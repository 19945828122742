import { collection, deleteDoc, doc, getDoc, increment, onSnapshot, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { db } from '../FirebaseConfig';
import { toast } from 'react-toastify';
import { toastOptions } from '../lib/toastOptions';

// const favStore = (set) => ({
//     favorites: [],

//     addFavorite: (favId) => {
//         set((state) => {
//             if (state.favorites.includes(favId)) {
//                 return state; // No changes if the ID already exists
//             }
//             return {
//                 favorites: [favId, ...state.favorites],
//             };
//         });
//     },

//     removeFavorite: (favId) => {
//         set((state) => ({
//             favorites: state.favorites.filter((id) => id !== favId),
//         }));
//     },
// });

// const useFavStore = create(
//     devtools(favStore)
// );

// export default useFavStore;


const favStore = (set, get) => ({
    
    favorites: [],
    setFavorites: (newfavorites) => set((state) => ({ ...state, favorites: newfavorites })),
     // Function to remove a product from favorites
     deleteFavorite: (productId) => 
        set((state) => ({
          favorites: Array.isArray(state.favorites) 
            ? state.favorites.filter((id) => id !== productId) // Ensure favorites is an array
            : [],
        })),

    addFavorite: async (product, user) => {
      if (!user) return;
  
      const userRef = doc(db, "users", user.uid);
      const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
      const productRef = doc(collection(favoritesRef, "products"), product.id);
  
      try {
        await setDoc(productRef, {
          timestamp: serverTimestamp(),
          likeCount: 0,
        });
        await updateDoc(userRef, { shoppingListCount: increment(1) });
        await updateDoc(doc(db, "products", product.id), { favourites: increment(1) });
  
        set((state) => ({
          favorites: [product.id, ...state.favorites],
        }));
        toast.success(`${product.name} added to favorites`, toastOptions);
      } catch (error) {
        console.error("Error adding favorite:", error);
      }
    },
  
    removeFavorite: async (product, user) => {
      if (!user) return;
  
      const userRef = doc(db, "users", user.uid);
      const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
      const productRef = doc(collection(favoritesRef, "products"), product.id);
  
      try {
        await deleteDoc(productRef);
        await updateDoc(userRef, { shoppingListCount: increment(-1) });
        await updateDoc(doc(db, "products", product.id), { favourites: increment(-1) });
  
        set((state) => ({
          favorites: state.favorites.filter((id) => id !== product.id),
        }));
        
        toast.success(`${product.name} removed from favorites`, toastOptions);
      } catch (error) {
        console.error("Error removing favorite:", error);
      }
    },
  
    toggleFavorite: async (product, user) => {
      if (!user) return;
  
      const state = get();
      if (state.favorites.includes(product.id)) {
        await state.removeFavorite(product, user);
      } else {
        await state.addFavorite(product, user);
      }
    },

    checkFavoriteStatus: async (product, user) => {
        if (!user || !product) return;
    
        const userRef = doc(db, "users", user.uid);
        const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
        const productRef = doc(collection(favoritesRef, "products"), product.id);
        const productDoc = await getDoc(productRef);
    
        if (productDoc.exists()) {
          set((state) => ({
            favorites: [...state.favorites, product.id],
          }));
        }
        return productDoc.exists();
      },
  });
  
  const useFavStore = create(
    devtools(favStore)
  );
  
  export default useFavStore;