import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "./FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const InterestContext = createContext({});

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => auth.currentUser);
  const [loading, setLoading] = useState(true);
  const [underAge, setUnderAge] = useState(null);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [parentalConsent, setParentalConsent] = useState(null);
  const navigate = useNavigate();

  const googleSignIn = () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, googleProvider);
  };

  const facebookSignIn = () => {
    const facebookProvider = new FacebookAuthProvider();
    facebookProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, facebookProvider);
  };

  const logOut = () => {
    signOut(auth);
    setUser(null);
    setUnderAge(null);
    setParentalConsent(null);
    setIncompleteProfile(false);
  };

  const fetchUserData = async (uid) => {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUnderAge(userData.underAge);
      setParentalConsent(userData.parentalConsent);

      const { location, favBrands, favCategories } = userData;
      console.log("userdoc in context", userData);

      const isLocationIncomplete = !location || location === "";
      const isFavBrandsIncomplete = !favBrands || favBrands.length === 0;
      const isFavCategoriesIncomplete =
        !favCategories || favCategories.length === 0;

      setIncompleteProfile(
        isLocationIncomplete ||
          isFavBrandsIncomplete ||
          isFavCategoriesIncomplete
      );
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);

      if (currentUser) {
        await fetchUserData(currentUser.uid);
        
        if (!currentUser.emailVerified) {
          console.log("VerifyEmail is calling me byeeee");
          navigate("/VerifyEmail");
        }
      } else {
        setUnderAge(null);
        setParentalConsent(null);
        setIncompleteProfile(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const contextValue = {
    user,
    underAge,
    incompleteProfile,
    parentalConsent,
    googleSignIn,
    facebookSignIn,
    logOut,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};