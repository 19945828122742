import React, { useEffect, useState } from "react";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useNotificationStore from "../StatesStore/Notifications";
import {
  AcceptFriendRequest,
  FirstTimeListView,
  FollowedMe,
  FriendRequest,
  InviteAccepted,
  JoinedUser,
  ListProductLike,
  PostComment,
  PostLike,
  ShoppingListChatMessage,
} from "../Notifications/FeedNotificationTypes";
import { FeedGroupedNotification } from "../Notifications/FeedGroupNotification";
import { db } from "../FirebaseConfig";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";

const notificationComponents = {
  joined: JoinedUser,
  inviteAccepted: InviteAccepted,
  postLike: PostLike,
  postComment: PostComment,
  followedUser: FollowedMe,
  listChat: ShoppingListChatMessage,
  firstTimeListView: FirstTimeListView,
  listProductLike: ListProductLike,
  friendRequest: FriendRequest,
  acceptFriendRequest: AcceptFriendRequest,
};
function NotificationOnFeed() {
  const { user } = UserAuth();
  // const [notifications, setNotifications] = useState([]);
  //   const { userData } = useFetchUserData(user.uid);
  const [notifications, setNotifications] = useState([]);
  // const addNotifications = useNotificationStore((state) => state.addNotifications);

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, `feedNotifications`);

    // Query where user.uid is not a key in the 'id' object
    const q = query(
      notificationsRef,
      where("ownerId", "!=", user.uid), 
      orderBy("timestamp", "desc"),
      limit(5)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationData = querySnapshot.docs
          .map((docSnapshot) => {
            const notification = {
              notificationId: docSnapshot.id,
              ...docSnapshot.data(),
            };

            // If notification.id is an array and contains user.uid, remove it
            if (Array.isArray(notification.id)) {
              notification.id = notification.id.filter(id => id !== user.uid);

              // If the array becomes empty after filtering, skip this notification
              if (notification.id.length === 0) {
                return null;
              }
            }

            return notification;
          })
          .filter(notification => notification !== null);
        setNotifications(notificationData);
        console.log("notificationData", notificationData);
      },
      (error) => {
        console.error("Error fetching notifications in real-time: ", error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const renderNotification = (notification) => {
    // Check if the id is an array and if its length is greater than 1
    if (Array.isArray(notification.id) && notification.id.length > 1) {
      // Grouped notifications
      return (
        <FeedGroupedNotification
          key={notification.notificationId}
          notification={notification}
          groupedNotifications={notification}
          user={user}
        />
      );
    }

    // Single notification
    const NotificationComponent = notificationComponents[notification.type];
    if (!NotificationComponent) return null;
    return (
      <NotificationComponent
        key={notification.notificationId}
        notification={notification}
        user={user}
      />
    );
  };
  return (
    <>
      {/* <div className="card-body p-0"> */}
      {notifications.length > 0 && (
        <ul className="list-group list-group-flush list-unstyled mb-2">
          {notifications.map(renderNotification)}
        </ul>
      )}
      {/* </div> */}
      {/* {notifications.length <4 && (
        <div className="noti-footer">
         
        </div>
      )} */}
    </>
  );
}

export default NotificationOnFeed;
