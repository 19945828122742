import React from "react";
import { Link } from "react-router-dom";

const ShareMenu = ({ product, componentName}) => {
  const handleFacebookShare = (url, quote) => {
    // Open Facebook with the share URL and title
    const shareUrl = encodeURIComponent(url);
    const shareQuote = encodeURIComponent(quote);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://lookflock.com/#/ProductDetails/${shareUrl}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = (title, url) => {
    // Open WhatsApp with the share URL and title
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${title}: https://lookflock.com/#/ProductDetails/${url}`
      )}`
    );
  };
  const handleEmailShare = (title, shareUrl) => {
    // Open default email client with the share URL and title
    window.location.href = `mailto:?subject=${encodeURIComponent(
      title
    )}&body=${encodeURIComponent(shareUrl)}`;
  };
  return (
    <span class="nav-link icon-md  gap-2 p-0 nav-item ">
      <Link
        class="nav-link "
        id="eventActionShare"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-share fs-6"> </i>
      </Link>
      {/* <!-- Dropdown menu --> */}
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="eventActionShare"
      >
        <li>
          <Link
            class="dropdown-item"
            to=""
            onClick={() => handleWhatsappShare(product.name, product.id)}
          >
            {" "}
            <i class="bi bi-whatsapp"></i> Share on Whatsapp
          </Link>
        </li>
        <li>
          <Link
            onClick={() => handleFacebookShare(product.id, product.name)}
            class="dropdown-item"
            to=""
          >
            {" "}
            <i class="bi bi-facebook "></i> Share on Facebook
          </Link>
        </li>

        <li>
          <Link
            onClick={() => handleEmailShare(product.name, product.id)}
            class="dropdown-item"
            to=""
          >
            {" "}
            <i class="bi bi-envelope fa-fw pe-1"></i>Share via Email
          </Link>
        </li>
      </ul>
      {componentName === "productDescriptions" && "Share"}
    </span>
  );
};

export default ShareMenu;