import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

function BrandsPage() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const brandsCollectionRef = collection(db, "brands");
        const brandsQuery = query(brandsCollectionRef, orderBy("name", "asc"));
        const brandsSnapshot = await getDocs(brandsQuery);
        const brandsData = brandsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setBrands(brandsData);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };

    getBrands();
  }, []);

  // Extract unique first letters from brand names
  const uniqueFirstLetters = Array.from(new Set(brands.map((brand) => brand.name[0]))).sort();
  // Generate an array containing all English alphabets
  const allAlphabets = Array.from({ length: 26 }, (_, i) => String.fromCharCode("A".charCodeAt(0) + i));

  const scrollToLetter = (letter) => {
    let targetLetter = letter.toUpperCase();
    const availableLetters = uniqueFirstLetters.map((letter) => letter.toUpperCase());
  
    if (!availableLetters.includes(targetLetter)) {
      // Find the nearest available letter
      const nearestLetter = availableLetters.reduce((a, b) => {
        return Math.abs(b.charCodeAt(0) - targetLetter.charCodeAt(0)) < Math.abs(a.charCodeAt(0) - targetLetter.charCodeAt(0)) ? b : a;
      });
  
      // Update the targetLetter to the nearest available letter
      targetLetter = nearestLetter;
    }
  
    const element = document.getElementById(targetLetter);
    if (element) {
      const yOffset = -70; // Adjust this value as needed to center the letter
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  
  
  
  return (
    <div className="container mt-5">
      <h2 className="mb-5 fs-4 fs-md-3 fs-lg-2">Brands directory</h2>
      {loading ? (
        <p>Loading brands...</p>
      ) : (
        <div>
          <div className="alphabet-list" style={{ overflowX: "auto" }}>
            {allAlphabets.map((letter) => (
              <Link
                to={`#${letter}`}
                key={letter}
                className={`alphabet-link text-uppercase`}
                onClick={() => scrollToLetter(letter)}
              >
                {letter}
              </Link>
            ))}
          </div>
          <hr className="border-top border-2 my-4" />

          <div className="my-4 brand-list">
            {uniqueFirstLetters.map((letter) => (
              <div key={letter} id={letter} className="mb-3">
                <div className="row align-items-center">
                  <div className="col-md-2">
                    <h1>{letter}</h1>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                    {brands
  .filter((brand) => brand.name[0] === letter)
  .map((brand) => (
    <div key={brand.id} className="col-md-6">
      <ul className="list-unstyled">
      <li>
  <Link
    to={`/BrandHome/${brand.name}`}
    className="d-flex justify-content-start align-items-center"
  >
    <img
      src={brand?.logoUrl}
      alt={`${brand.displayName} logo`}
      style={{ width: "24px", height: "24px",borderRadius: "2px" }}
    />
    <span className="ms-2">{brand.displayName}</span>
  </Link>
</li>
      </ul>
    </div>
  ))}
                    </div>
                    {brands.filter((brand) => brand.name[0] === letter).length % 2 !== 0 && (
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 border-right"></div>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="border-top border-2 my-4" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default BrandsPage;