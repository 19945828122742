import React, { useEffect } from "react";
import useModalStore from "../StatesStore/ModalStore";
import { DashSquare, EnvelopeCheck, PlusSquare } from "react-bootstrap-icons";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import { message } from "antd";
// import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { UserAuth } from "../Context";
import { useState } from "react";
import { db } from "../FirebaseConfig";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { saveInviteToDb } from "../lib/saveInviteToDb";
const { ShoppingListMail } = require("../EmailSystem/sendEmails");

function ShoppingListInvite() {
  const { data, closeModal } = useModalStore();
  const [users, setUsers] = useState([{ name: "", email: "" }]);
  const { user } = UserAuth();
  const [Error, setError] = useState("");
  const regex = /^[a-zA-Z\s]*$/;
  const myEmail = user?.providerData[0].email;
  const text = "Invite Friends";
  const isFirstUserEmpty =
    users[0]?.name === "" && users[0]?.email === "" && users?.length === 1;
  const [productImgUrls, setProductImgUrls] = useState([]);
  const [profile, setProfile] = useState("");
  const checkEmails = async () => {
    const emailSet = new Set();
    emailSet.add(myEmail);
    for (const person of users) {
      if (person.email === myEmail) {
        setError("Cannot email yourself");
        return false; // Email is same as user's email
      }
      if (emailSet.has(person.email)) {
        setError("Emails should be unique");
        return false; // Duplicate found
      }

      emailSet.add(person.email);
    }
    return true;
  };
  const onEmailFinish = (values) => {
    values.forEach(async (userData) => {
      const { name, email } = userData;
      // console.log(user, data);
      // console.log(data.shareURL);
      ShoppingListMail(
        data.myName,
        name,
        email,
        data.listName,
        data.shareURL,
        productImgUrls,
        profile
      )
        .then(() => {
          message.success({
            content: `Email sent successfully to ${email}`,
            key: "success",
          });
        })
        .catch((error) => {
          message.error(`Error sending email to ${email}`);
          console.error("Error sending email:", error);
        });
      closeModal();
      await saveInviteToDb(
        {
          name,
          email,
          type: "email",
        },
        user
      );
    });
  };
  const handleAdd = () => {
    setUsers([...users, { name: "", email: "" }]);
  };

  const handleRemove = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  const handleChange = (index, field, value) => {
    const newUsers = [...users];
    newUsers[index][field] = value;
    setUsers(newUsers);
  };

  const fetchShoppingListProducts = async () => {
    const elements = data.shareURL.split("/");
    // console.log(data.shareURL.split("#")[0]);
    // console.log("elements[5]", elements[5]);
    // console.log(elements[6]);
    const userRef = doc(db, "users", elements[6]);
    const userSnap = await getDoc(userRef);
    const profilePhoto = userSnap?.data()?.photo;
    setProfile(profilePhoto);
    const shopListRef = collection(db, "users", elements[6], "shoppingList");
    const shopList = await getDocs(shopListRef);
    const list = shopList.docs.filter(
      (doc) => doc?.data()?.listId === elements[5]
    );
    console.log("list123", list[0]?.id);
    const productRef = collection(shopListRef, list[0]?.id, "products");
    const productSnap = await getDocs(productRef);
    const products = productSnap.docs.map((doc) => doc?.id);
    if (products) {
      const ImgUrls = await Promise.all(
        products.map(async (product) => {
          const productRef = doc(db, "products", product);
          const productSnap = await getDoc(productRef);
          return { id: productSnap?.id, image: productSnap?.data().imageUrl };
        })
      );
      setProductImgUrls(ImgUrls);
      console.log("ImgUrls", ImgUrls);
    }
  };
  useEffect(() => {
    fetchShoppingListProducts();
  }, [data]);

  return (
    <Modal show={true} centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h6 className="text-body">Invite via Email</h6>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              console.log("SUBMIT");
              if (await checkEmails()) {
                onEmailFinish(users);
              }
            }}
          >
            {users.map((user, index) => (
              <InputGroup
                key={index}
                className="mb-1 flex justify-content-center align-items-center"
              >
                <Form.Control
                  className="me-1 rounded h-50"
                  placeholder="Name"
                  value={user?.name}
                  onChange={(e) => {
                    if (regex.test(e.target.value)) {
                      handleChange(index, "name", e.target.value);
                      setError("");
                    } else {
                      setError(
                        "Name cannot contain numbers or special characters"
                      );
                    }
                  }}
                  required
                />
                <Form.Control
                  className="ms-1 rounded h-50"
                  type="email"
                  placeholder="Email"
                  value={user?.email}
                  onChange={(e) => handleChange(index, "email", e.target.value)}
                  required
                />
                <button
                  className="btn border-0 px-2 pt-1"
                  onClick={() => handleRemove(index)}
                  disabled={isFirstUserEmpty}
                >
                  <DashSquare className="fs-4" />
                </button>
                <button className="btn border-0 px-2 pt-1" onClick={handleAdd}>
                  <PlusSquare className="fs-4 " />
                </button>
              </InputGroup>
            ))}
            <span className="ms-2 text-danger">{Error}</span>

            <div className="d-flex justify-content-end">
              <Button
                className="rounded d-flex align-items-center"
                type="submit"
                variant="primary-soft"
                size="sm"
              >
                <EnvelopeCheck className="fs-6 me-2" />
                <span>Invite</span>
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ShoppingListInvite;
