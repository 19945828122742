import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import formatTimestamp from "../lib/formatTimestamp";
import { useFetchUserData } from "../hooks/user/useFetchUserData";

function FeedNotification({
  notification,
  message,
  route,
  firstUserData,
  user,
}) {
  const navigate = useNavigate();
  const [isSeen, setIsSeen] = useState(notification.seen);
  const { userData:ownerData } = useFetchUserData(notification?.ownerId);
  console.log("ownerData from feed notification",ownerData,notification);
  
  const handleNavigate = async () => {
    try {
      // Navigate to the dynamic route
      navigate(route);

      // Update the 'seen' attribute to true in Firestore
      if (!isSeen) {
        setIsSeen(true);
        const notificationRef = doc(
          db,
          "users",
          user?.uid,
          "notifications",
          notification.notificationId
        );
        await updateDoc(notificationRef, { seen: true });
      }
    } catch (error) {
      console.error("Error updating notification: ", error);
    }
  };
  console.log("firstUserData from feed notification", firstUserData);

  return (
    <div
      className="d-flex m-1"
      onClick={handleNavigate}
      style={{ display: "inline-flex", gap: "6px", cursor: "pointer" }}
    >
      {/* Avatar */}
      <div className="avatar">
        <Link
          to={
            firstUserData
              ? `/userProfile/${firstUserData?.userId}`
              : "/userProfile"
          }
        >
          <ProfilePicture
            userData={firstUserData}
            className="avatar-img rounded"
          />
        </Link>
      </div>

      {/* Notification Content */}
      <div className="card w-100 d-flex align-items-center justify-content-center">
        <div className="ms-2 w-100 d-flex justify-content-between align-items-center p-1">
          <div className="d-flex flex-grow-1">
            <p className="small mb-0">
              <b>{firstUserData?.userName}</b> {message} <b>{ownerData?.userName}.</b>
            </p>
          </div>
          <div className="small me-3">
            {formatTimestamp(notification.timestamp)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedNotification;
