import {
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  arrayUnion,
  writeBatch,
  increment,
  getDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";

export const handleInvite = async (inviteCode, authUser, email,pointsTable) => {
 
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "users"), where("referralCode", "==", inviteCode))
    );

    // Check the inviteList collection for any document containing the matching email
    const inviteListSnapshot = await getDocs(
      query(collection(db, "inviteList"), where("email", "==", email))
    );
    console.log("email from handleinvite and authUser", email, authUser);
    // If matching invites are found, update the timestamp and joined status for all
    if (!inviteListSnapshot.empty) {
      inviteListSnapshot.forEach(async (inviteDoc) => {
        const inviteListDocRef = inviteDoc.ref;
        const invitedBy = inviteDoc.data().invitedBy; // Get the invitedBy field
        const userRef = doc(db, "users", invitedBy);
        const invitesRef = collection(userRef, "invites");
        const pointsLogRef = collection(userRef, "pointsLog");

        const userDoc = await getDoc(userRef);
        const invitedUserCount = userDoc?.data()?.invitedUserCount || 0;

        const senderConnectionRef = doc(
          db,
          "users",
          authUser.user?.uid,
          "connections",
          invitedBy
        );
        const receiverConnectionRef = doc(
          db,
          "users",
          invitedBy,
          "connections",
          authUser.user?.uid
        );
        const followingRef = doc(
          db,
          "users",
          invitedBy,
          "followers",
          authUser.user?.uid
        );
        const followerRef = doc(
          db,
          "users",
          authUser.user?.uid,
          "following",
          invitedBy
        );
        // Update the invite document with a new timestamp and set joined to true
        await updateDoc(inviteListDocRef, {
          timestamp: serverTimestamp(),
          joined: true,
        });

        // Add a notification to the 'invitedBy' user's notifications subcollection
        const invitedByNotificationsRef = collection(
          db,
          "users",
          invitedBy,
          "notifications"
        );
        await setDoc(doc(invitedByNotificationsRef), {
          id: authUser.user?.uid, // The ID of the user who joined
          type: "inviteAccepted",
          by: "user",
          timestamp: serverTimestamp(),
          seen: false,
        });
        // Start a batch operation
        const batch = writeBatch(db);

        // Add invite data to the user's invites subcollection
        batch.set(doc(invitesRef, authUser.user?.uid), {
          dateCreated: serverTimestamp(),
          email: email,
        });

        // Update connection status for both users
        batch.set(senderConnectionRef, {
          type: "active",
          timestamp: serverTimestamp(),
        });
        batch.set(receiverConnectionRef, {
          type: "active",
          timestamp: serverTimestamp(),
        });

        // Add to following subcollection
        batch.set(followingRef, {
          type: "user",
          timestamp: serverTimestamp(),
        });

        // Add to followers subcollection
        batch.set(followerRef, {
          type: "user",
          timestamp: serverTimestamp(),
        });

        // Commit the batch operation
        await batch.commit();

        //Add points
      const pointsAwarded = pointsTable.userJoinedAfterInvitation || 0;
        // Increment the user's points 
      await updateDoc(userRef, {
        points: increment(pointsAwarded),
      });
       // Log the points in the pointsLog subcollection
       await setDoc(doc(pointsLogRef), {
        type: "userJoinedAfterInvitation",
        pointsAwarded: pointsAwarded,
        timestamp: serverTimestamp(),
 
      });

      updateDoc(userRef, {
        invitedUserCount: increment(1),
      });

      if ((invitedUserCount + 1) % 10 === 0 && invitedUserCount > 0) {
        // Award 100 points to the user
        const pointsAwarded = pointsTable.every10SuccessfulInvites || 0;

        await updateDoc(userRef, {
          points: increment(pointsAwarded),
        });
         // Log the points in the pointsLog subcollection
         await setDoc(doc(pointsLogRef), {
          type: "every10SuccessfulInvites",
          pointsAwarded: pointsAwarded,
          timestamp: serverTimestamp(),
          invitedUserCount:invitedUserCount+1
        });
        console.log(`Awarded ${pointsAwarded} points to user for ${invitedUserCount} invites`);
      }

      });
    } else if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0]; // Assuming there's only one user with the matching referral code
      const userRef = doc(db, "users", userDoc.id); // Define userRef here
      const inviteUserRef = doc(db, "users", authUser.user?.uid);
      const invitesRef = collection(userRef, "invites");
      const notificationsRef = collection(userRef, "notifications");
      const pointsLogRef = collection(userRef, "pointsLog");
      const user = await getDoc(userRef);
      const invitedUserCount = user?.data()?.invitedUserCount || 0;


      const senderConnectionRef = doc(
        db,
        "users",
        authUser.user?.uid,
        "connections",
        userDoc.id
      );
      const receiverConnectionRef = doc(
        db,
        "users",
        userDoc.id,
        "connections",
        authUser.user?.uid
      );
      const followingRef = doc(
        db,
        "users",
        userDoc.id,
        "followers",
        authUser.user?.uid
      );
      const followerRef = doc(
        db,
        "users",
        authUser.user?.uid,
        "following",
        userDoc.id
      );

      // Start a batch operation
      const batch = writeBatch(db);

      // Add invite data to the user's invites subcollection
      batch.set(doc(invitesRef, authUser.user?.uid), {
        dateCreated: serverTimestamp(),
        email: email,
      });

      // Add a notification to the user's notifications subcollection
      batch.set(doc(notificationsRef), {
        id: authUser.user?.uid,
        timestamp: serverTimestamp(),
        by: "user",
        type: "inviteAccepted",
        seen: false,
      });

      // Update connection status for both users
      batch.set(senderConnectionRef, {
        type: "active",
        timestamp: serverTimestamp(),
      });
      batch.set(receiverConnectionRef, {
        type: "active",
        timestamp: serverTimestamp(),
      });

      // Add to following subcollection
      batch.set(followingRef, {
        type: "user",
        timestamp: serverTimestamp(),
      });

      // Add to followers subcollection
      batch.set(followerRef, {
        type: "user",
        timestamp: serverTimestamp(),
      });

      // Commit the batch operation
      await batch.commit();

      updateDoc(userRef, {
        invitedUserCount: increment(1),
      });

        //Add points
        const pointsAwarded = pointsTable.userJoinedAfterInvitation || 0;
        // Increment the user's points 
      await updateDoc(userRef, {
        points: increment(pointsAwarded),
      });
       // Log the points in the pointsLog subcollection
       await setDoc(doc(pointsLogRef), {
        type: "userJoinedAfterInvitation",
        pointsAwarded: pointsAwarded,
        timestamp: serverTimestamp(),
 
      });

      if ((invitedUserCount + 1) % 10 === 0 && invitedUserCount > 0) {
        // Award 100 points to the user
        const pointsAwarded = pointsTable.every10SuccessfulInvites || 0;

        await updateDoc(userRef, {
          points: increment(pointsAwarded),
        });
         // Log the points in the pointsLog subcollection
         await setDoc(doc(pointsLogRef), {
          type: "every10SuccessfulInvites",
          pointsAwarded: pointsAwarded,
          timestamp: serverTimestamp(),
          invitedUserCount:invitedUserCount+1
        });
        console.log(`Awarded ${pointsAwarded} points to user for ${invitedUserCount} invites`);
      }

      console.log(
        "Invite, connections, and following/followers updated successfully!"
      );
    }
  } catch (error) {
    console.error("Error adding invite:", error);
    // Handle error here
  }
};
