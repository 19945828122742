import React, { useEffect, useState } from "react";
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";

import {JoinedUser,PostLike,PostComment,FollowedMe,ShoppingListChatMessage,FirstTimeListView,ListProductLike, FriendRequest, AcceptFriendRequest, InviteAccepted} from "./NotificationTypes" 
import { GroupedNotification } from "./GroupNotification";
import useNotificationStore from "../StatesStore/Notifications";

const notificationComponents = {
  joined: JoinedUser,
  inviteAccepted: InviteAccepted,
  postLike: PostLike,
  postComment: PostComment,
  followed: FollowedMe,
  listChat: ShoppingListChatMessage,
  firstTimeListView: FirstTimeListView,
  listProductLike: ListProductLike,
  friendRequest:FriendRequest,
  acceptFriendRequest:AcceptFriendRequest
};

function Notification({ user }) {
  const [notifications, setNotifications] = useState([]);
  const addNotifications = useNotificationStore((state) => state.addNotifications);

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, `users/${user.uid}/notifications`);
    const q = query(notificationsRef, orderBy("timestamp", "desc"), limit(5));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationData = querySnapshot.docs.map((docSnapshot) => ({
          notificationId: docSnapshot.id,
          ...docSnapshot.data(),
        }));
        setNotifications(notificationData);
        addNotifications(notificationData)
      },
      (error) => {
        console.error("Error fetching notifications in real-time: ", error);
      }
    );

    return () => unsubscribe();
  }, [user]);
 

  const renderNotification = (notification) => {
    // Check if the id is an array and if its length is greater than 1
    if (Array.isArray(notification.id) && notification.id.length > 1) {
      // Grouped notifications
      return (
        <GroupedNotification
          key={notification.notificationId}
          notification={notification}
          groupedNotifications={notification}
          user={user}
        />
      );
    }

    // Single notification
    const NotificationComponent = notificationComponents[notification.type];
    if (!NotificationComponent) return null;
    return (
      <NotificationComponent
        key={notification.notificationId}
        notification={notification}
        user={user}
      />
    );
  };

  return (
    <li className="nav-item dropdown ms-2">
      <a
        className="nav-link icon-md btn btn-light p-0"
        id="notifDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        {notifications.length > 0 && <span className="badge-notif animation-blink"></span>}
        <i className="bi bi-bell-fill fs-6"></i>
      </a>
      <div
        className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0"
        aria-labelledby="notifDropdown"
      >
        <div className="card">
          <div className={`card-header d-flex justify-content-between align-items-center ${notifications.length < 4 ? 'no-border-bottom' : ''}`}>
            <h6 className="m-0">
              Notifications{" "}
              <span className="badge bg-danger bg-opacity-10 text-danger ms-2">
                {notifications.length} new
              </span>
            </h6>
          </div>
          <div className="card-body p-0">
            {notifications.length > 0 && (
              <ul className="list-group list-group-flush list-unstyled p-2">
                {notifications.map(renderNotification)}
              </ul>
            )}
          </div>
          {notifications.length <4 && (
            <div className="noti-footer">
             
            </div>
          )}
          {notifications.length > 4 && (
            <div className="card-footer text-center">
              <Link to="/notifications" className="btn btn-sm btn-primary-soft">
                See all notifications
              </Link>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default Notification;
