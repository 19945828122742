import React, { useState, useEffect } from "react";
import ProductList from "./ProductList";
import ListComments from "./ListComments";
import { Link, useSearchParams } from "react-router-dom";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  deleteDoc,
  increment,
} from "firebase/firestore";
import InviteOnList from "./InviteOnList";
import Settings from "./Settings";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { getBaseURL } from "../lib/getBaseURL";
import useModalStore from "../StatesStore/ModalStore";
import ListShareMenu from "../components/ListShareMenu";
import InvitedFriendsAvatar from "./InvitedFriendsAvatar";
import useFavStore from "../StatesStore/FavStore";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";

const MyList = ({ shoppingListData, setShoppingListData }) => {
  // When someone deletes a product it should be removed in the fav state
  const removeFavoriteProduct = useFavStore((state) => state.removeFavorite);
  const favoriteProduct = useFavStore((state) => state.favorites);
  // The list which is currently selected
  const [selectedShopList, setSelectedShopList] = useState({});
  // Current List ID
  const [listId, setListId] = useState("");
  const baseURL = getBaseURL();
  const { openModal } = useModalStore();
  const { user } = UserAuth();

  // Get search parameters from the URL
  const [searchParams] = useSearchParams();
  // Extract 'name' and 'id' from the URL query params
  const listName = searchParams.get("name");
  const urlId = searchParams.get("id");

  useEffect(() => {
    console.log("SelectedShopList", selectedShopList);
  }, [selectedShopList]);

  useEffect(() => {
    // If 'name' and 'id' are present in the URL, find the matching shopping list
    if (listName && urlId && shoppingListData?.length > 0) {
      const matchedList = shoppingListData.find(
        (shopList) => shopList.name === listName && shopList.listId === urlId
      );
      if (matchedList) {
        setSelectedShopList({
          name: matchedList.name,
          listId: matchedList.listId,
          products: matchedList.products,
        });
        setListId(matchedList.listId);
      }
    } else if (shoppingListData?.length > 0) {
      // Fallback to default selection if URL params are not present
      setSelectedShopList({
        name: shoppingListData[0]?.name,
        listId: shoppingListData[0]?.listId,
        products: shoppingListData[0]?.products,
      });
      setListId(shoppingListData[0]?.listId);
    }
  }, [listName, urlId, shoppingListData]);

  useEffect(() => {
    if (!selectedShopList) return;
    console.log("selectedShopList", selectedShopList);
  }, [selectedShopList]);

  const handleClick = () => {
    const DATA = {
      deleteFunction: handleDeleteList,
      displayName: selectedShopList?.name,
    };
    openModal("ConfirmDelete", "shoppingList", DATA);
  };
  const handleDeleteList = async () => {
    if (!user) return;
    try {
      const selectedShoppingListDoc = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.name
      );
      const docCountRef = collection(selectedShoppingListDoc, "products");
      const docCountSnap = await getDocs(docCountRef);
      const countDocs = docCountSnap.size;
      console.log(countDocs);
      const userRef = doc(db, "users", user?.uid);
      // Delete the shopping list
      await deleteDoc(selectedShoppingListDoc);
      console.log("List deleted successfully");
      // eslint-disable-next-line
      favoriteProduct.map((favProd) => {
        removeFavoriteProduct(favProd);
      });
      console.log(favoriteProduct);
      // Update the local state
      const remainingLists = shoppingListData.filter(
        (list) => list?.name !== selectedShopList?.name
      );

      // Sort the remaining lists by dateCreated in descending order
      remainingLists.sort((a, b) => a.dateCreated - b.dateCreated);

      // Set selectedShopList to the name of the first list in the sorted array
      setSelectedShopList({
        name: remainingLists[0]?.name,
        listId: remainingLists[0]?.listId,
      });

      // Update the local state
      setShoppingListData(remainingLists);

      // Update the user document with the decremented count
      await updateDoc(userRef, {
        shoppingListCount: increment(-countDocs),
      });
    } catch (error) {
      console.error("Error adding product to the list:", error.message);
    }
  };
  const handleshopListClick = async (shopListName, shopListId) => {
    const selectedShopListData = shoppingListData?.find(
      (shopList) =>
        shopList?.name === shopListName && shopList?.listId === shopListId
    );
    if (selectedShopListData) {
      setListId(selectedShopListData?.listId);
    }
    setSelectedShopList({
      name: selectedShopListData?.name,
      listId: selectedShopListData?.listId,
      products: selectedShopListData?.products,
    });
  };
  return (
    <>
      <div className="card pt-0 border border-0 rounded-2">
        <div
          className={`d-sm-flex justify-content-between align-items-center bg-transparent mt-2 nav px-3 ${
            shoppingListData?.length > 0 ? "" : "border-bottom-0"
          }`}
        >
          {shoppingListData?.length > 0 && (
            <ul
              className={`nav nav-bottom-line d-sm-flex align-items-center justify-content-center justify-content-md-start listNames`}
              style={{
                overflowX: "scroll",
                flexWrap: "nowrap",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "29rem",
                maxHeight: "4rem",
              }}
            >
              {/* ActiveList */}
              {shoppingListData.map((shopList) => (
                <li className="nav-item" key={shopList?.name}>
                  <Link
                    className={`nav-link card-title px-2 py-3 m-0 d-flex align-items-center justify-content-center ${
                      selectedShopList?.name === shopList?.name &&
                      selectedShopList?.listId === shopList?.listId
                        ? "active"
                        : ""
                    }`}
                    to=""
                    onClick={() =>
                      handleshopListClick(shopList?.name, shopList?.listId)
                    }
                  >
                    <div>{shopList?.name}</div>
                    <div
                      className="bg-primary fw-normal ms-2 text-white rounded-pill"
                      style={{ fontSize: "10px", padding: "1px 8px" }}
                    >
                      {shopList.products?.length}
                    </div>
                  </Link>
                </li>
              ))}
              <style jsx>
                {`
                  .listNames::-webkit-scrollbar {
                    height: 1px;
                  }
                `}
              </style>
            </ul>
          )}

          {shoppingListData?.length > 0 && (
            <>
              <ul className="d-none d-sm-flex nav gap-2 pb-0 align-items-center justify-content-center justify-content-sm-end">
                <InvitedFriendsAvatar
                  listName={selectedShopList?.name}
                  listId={listId}
                  userId={user?.uid}
                />
                <InviteOnList
                  selectedShopList={selectedShopList}
                  listId={listId}
                />
                <ListShareMenu
                  listName={selectedShopList?.name}
                  myName={user?.displayName}
                  listLink={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                  listId={listId}
                  userId={user?.uid}
                  products={selectedShopList.products}
                />
                <Settings selectedShopList={selectedShopList?.name} />
                <li className="nav-item">
                  <Link
                    className="icon-md btn btn-light p-0 text-body"
                    to=""
                    onClick={handleClick}
                  >
                    <i className="bi bi-trash-fill fs-6"> </i>
                  </Link>
                </li>
              </ul>
              <style jsx>
                {`
                  .dropdown-toggle:after {
                    display: none;
                  }
                `}
              </style>
              {/* DropDown for small screen */}
              <Dropdown className="d-sm-none pb-2">
                <Dropdown.Toggle as="a" className="nav-link btn p-0 fs-3">
                  <ThreeDotsVertical />
                </Dropdown.Toggle>
                {/* <!-- Dropdown menu --> */}
                <Dropdown.Menu className="dropdown-menu-end mt-0 p-2">
                  <Dropdown.Item className="p-0">
                    <InviteOnList
                      selectedShopList={selectedShopList?.name}
                      listId={listId}
                    />
                  </Dropdown.Item>
                  <ListShareMenu
                    listName={selectedShopList?.name}
                    myName={user?.displayName}
                    listLink={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                    listId={listId}
                    userId={user?.uid}
                    products={selectedShopList.products}
                  />
                  <Dropdown.Item className="p-0">
                    <Settings selectedShopList={selectedShopList?.name} />
                  </Dropdown.Item>
                  <Dropdown.Item className="p-0">
                    <div className="d-flex align-items-center">
                      <li className="nav-item" onClick={handleClick}>
                        <Link
                          className="icon-md btn btn-light p-0 text-body"
                          to=""
                        >
                          <i className="bi bi-trash-fill fs-6"> </i>
                        </Link>
                        <span className="mx-2 h6 fw-light">Delete list</span>
                      </li>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
        {shoppingListData?.length > 0 && <hr className="m-0" />}
        <ProductList
          shoppingListData={shoppingListData}
          selectedShopListName={selectedShopList?.name}
          selectedShopListId={selectedShopList?.listId}
          userId={user?.uid}
          listId={listId}
        />
        {shoppingListData.some(
          (shopList) =>
            shopList.name === selectedShopList?.name &&
            shopList.products?.length > 0
        ) && (
          <ListComments
            userId={user?.uid}
            listId={listId}
            shoppingListData={shoppingListData}
            selectedShopList={selectedShopList?.name}
            notify={false}
          />
        )}
      </div>
    </>
  );
};

export default MyList;
