import React, { useState } from 'react';
import formatTimestamp from '../lib/formatTimestamp';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from '../UserProfile/ProfilePicture';
import { db } from '../FirebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';

function NotificationItem({ notification, message, route,firstUserData,user }) {

  const navigate = useNavigate();
  const [isSeen, setIsSeen] = useState(notification.seen);

  const handleNavigate = async () => {
    try {
      // Navigate to the dynamic route
      navigate(route);

      // Update the 'seen' attribute to true in Firestore
      if (!isSeen) {
        setIsSeen(true); 
        const notificationRef = doc(db, 'users', user?.uid, 'notifications', notification.notificationId);
        await updateDoc(notificationRef, { seen: true });
        
      }
    } catch (error) {
      console.error('Error updating notification: ', error);
    }
  };

  return (
    <li onClick={handleNavigate} style={{ cursor: 'pointer' }}>
         <div
        className={`list-group-item list-group-item-action rounded ${
          isSeen ? '' : 'badge-unread'
        } d-flex border-0 mb-1 p-3 position-relative`}
      >
        <div className="avatar text-center d-none d-sm-inline-block">
          <ProfilePicture userData={firstUserData} className='avatar-img rounded' />
        </div>
        <div className="ms-sm-3 d-flex justify-content-between w-100">
          <div>
            <p className="small mb-0">
              <b>{firstUserData?.userName}</b> {message}
            </p>
          </div>
          <p className="small ms-3">{formatTimestamp(notification.timestamp)}</p>
        </div>
      </div>
    </li>
  );
}

export default NotificationItem;
