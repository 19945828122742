import React, { useEffect, useRef, useState } from "react";
import UserMenuHeader from "../Authentication/UserMenuHeader";
import SignInMenuHeader from "../Authentication/SignInMenuHeader";
import { UserAuth } from "../Context";
import HeaderMenu2 from "./HeaderMenu2";
import { Link, useHistory } from "react-router-dom";

import "./style.css";
import { Button, Container, FormControl, Nav, Navbar } from "react-bootstrap";

function Header() {
  const { user } = UserAuth();
  const [activeAccordion, setActiveAccordion] = useState(null);
  // Input state is used to store user input
  const [input, setInput] = useState("");

  // Handle outside click
  const handleOutsideClick = () => {
    const main = document.getElementById("main");
    const navbarCollapsible = document.getElementById("navbarCollapse");

    function fun() {
      if (navbarCollapsible.classList.contains("show")) {
        main.setAttribute("data-bs-toggle", "collapse");
        main.setAttribute("data-bs-target", "#navbarCollapse");
      } else {
        main.removeAttribute("data-bs-target", "#navbarCollapse");
        main.removeAttribute("data-bs-toggle", "collapse");
      }
      setActiveAccordion(0);
      document.querySelector(".brandsScrollBar").scrollTo(0, 0);
      // console.log(main);
      // console.log(navbarCollapsible);
    }
    setTimeout(fun, 400);
  };

  useEffect(() => {
    const main = document.getElementById("main");
    main.addEventListener("click", handleOutsideClick);
    return () => {
      main.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (input.length > 2) {
      window.location.href = `#/search/${input}`;
    }
  };
  return (
    <>
      <Navbar className="shadow-sm bg-mode">
        <Container className="text-center align mt-2 mt-lg-0 mt-sm-1 mt-xs-0">
          {/* Logo START */}
          <Link className="text-body text-center fs-icon" to="/">
            <div style={{ fontFamily: "Nunito Sans" }}>
              <i>lookflock &nbsp;</i>
            </div>
          </Link>
          {/* Logo END */}

          {/* Nav Search START */}
          <Nav className="d-none d-lg-block nav mt-3 mt-lg-0 flex-nowrap justify-content align-items-center px-4 px-lg-0">
            <Nav.Item className="w-100">
              <form
                className="rounded position-relative"
                onSubmit={handleSearch}
              >
                <FormControl
                  className="ps-5 bg-light"
                  type="search"
                  name="input"
                  placeholder="Search..."
                  aria-label="Search"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <Button
                  variant="mode"
                  type="submit"
                  className="bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
                >
                  <i className="bi bi-search fs-5"></i>
                </Button>
              </form>
            </Nav.Item>
          </Nav>

          {/* Responsive navbar toggler */}
          <div className="navbar-nav ms-auto bg-transparent">
            <button
              id="closeNav"
              className="d-none d-lg-block border border-0 nav-link ms-auto bg-transparent"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleOutsideClick}
            >
              Menu
              <i className="fs-6 bi bi-chevron-down px-2"></i>
            </button>
            <button
              className="d-lg-none d-block navbar-toggler ms-auto icon-md btn btn-light p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </span>
            </button>
          </div>

          {/* Nav right START */}
          <ul className="nav flex-nowrap align-items-center list-unstyled position-relative ps-2">
            {user ? <UserMenuHeader /> : <SignInMenuHeader />}
          </ul>
        </Container>
      </Navbar>

      {/* Collapsed Content */}
      <div className="bg-mode collapse navbar-collapse" id="navbarCollapse">
        <div className="py-4 container">
          <HeaderMenu2
            handleOutsideClick={handleOutsideClick}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          />
        </div>
      </div>
    </>
  );
}

export default Header;
