import React, { useEffect, useState } from 'react';
import NotificationItem from './NotificationItem';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import FeedNotification from '../Cards/FeedNotification';

// Component to render notifications based on the user IDs in the notification.id array
export function GroupedNotification({ notification,user,feed }) {
  const [users, setUsers] = useState([]);
  
  // Fetch user data for each user ID in the notification.id array
  useEffect(() => {
    async function fetchUserData() {
      try {
        const userIds = (notification?.id || []) // Reverse the IDs array // `notification.id` is an array of user IDs
        console.log(" user IDs:", userIds); 
        // console.log("Reversed user IDs:", userIds.reverse()); 
        // Only fetch data if there are at least two user IDs
        if (userIds.length >= 2) {
          const userData = await Promise.all(
            userIds.slice(0, 2).map(async (userId) => { // Fetch data for the first and second user
              const userDoc = await getDoc(doc(db, 'users', userId));
              if (userDoc.exists()) {
                return userDoc.data();  // Return user data if found
              } else {
                console.error(`No such user with ID ${userId}`);
                return null;
              }
            })
          );
          setUsers(userData.filter((user) => user !== null)); // Filter out any null values from the array
        } else {
          setUsers([]); // Set to empty if there are less than 2 user IDs
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUsers([]); // Set to empty in case of error
      }
    }

    fetchUserData();
  }, []);

  // Render notifications with fetched user data
  return (
    <>
      {users.length > 0 && (
        <NotificationItem
          key={notification.type}
          message={generateMessage(users, notification)}
          route={getRouteForNotification(notification,user)}
          firstUserData={users[0]} // Pass the first user's data
          secondUserData={users[1]}
          notification={notification} // Pass the entire notification for additional details
          feed={feed}
        />
      )}
    </>
  );
}

// Helper functions for message generation
function generateMessage(users, notification) {
  const userCount = notification.id.length;
  switch (notification.type) {
    case 'followed':
      return generateFollowMessage(users,userCount);
    case 'postLike':
      return generateLikeMessage(users,userCount);
    case 'postComment':
      return generateCommentMessage(users,userCount);
    case 'listProductLike':
      return generateListProductLikeMessage(users,userCount,notification);
    default:
      return '';
  }
}

function generateFollowMessage(users, userCount) {
  if (userCount === 1) return <> started following you.</>;
  if (userCount === 2) return <> and <b>{users[1].userName}</b> started following you.</>;
  if (userCount === 3) return <>, <b>{users[1].userName}</b> and <b>1</b> other started following you.</>;
  return <> , <b>{users[1].userName}</b>, and <b>{userCount - 2}</b> others started following you.</>;
}

function generateLikeMessage(users, userCount) {
  if (userCount === 1) return <> liked your post.</>;
  if (userCount === 2) return <> and <b>{users[1].userName}</b> liked your post.</>;
  if (userCount === 3) return <> , <b>{users[1].userName}</b> and <b>1</b> other liked your post.</>;
  return <> , <b>{users[1].userName}</b>, and <b>{userCount - 2}</b> others liked your post.</>;
}

function generateCommentMessage(users, userCount) {
  if (userCount === 1) return <> commented on your post.</>;
  if (userCount === 2) return <> and <b>{users[1].userName}</b> commented on your post.</>;
  if (userCount === 3) return <> , <b>{users[1].userName}</b> and <b>1</b> other commented on your post.</>;
  return <><b>{users[0].userName}</b>, <b>{users[1].userName}</b>, and <b>{userCount - 2}</b> others commented on your post.</>;
}

function generateListProductLikeMessage(users, userCount, notification) {
  if (userCount === 1) return <> liked your product in {notification.shopList} list.</>;
  if (userCount === 2) return <> and <b>{users[1].userName}</b> liked your product in {notification.shopList} list.</>;
  if (userCount === 3) return <> , <b>{users[1].userName}</b> and <b>1</b> other liked your product in {notification.shopList} list.</>;
  return <> , <b>{users[1].userName}</b>, and <b>{userCount - 2}</b> others liked your product in {notification.shopList} list.</>;
}

// Helper function to determine the route based on the notification type
function getRouteForNotification(notification,user) {
  switch (notification.type) {
    case 'followed':
      return `/userProfile/${user.uid}/userfollowers`;  // Redirect to the first user's profile
    case 'postLike':
      return `/post/${notification.postId}`;  // Redirect to the liked post
    case 'postComment':
      return `/post/${notification.postId}`;  // Redirect to the commented post
    case 'listProductLike':
      return `/shoppingList/${notification.listId}/${user.uid}`;  
    default:
      return '/';
  }
}
