import React, { useState } from "react";
import useModalStore from "../StatesStore/ModalStore";

import {
  Whatsapp,
  Facebook,
  PlusSquare,
  DashSquare,
  EnvelopeCheck,
  Link45deg,
} from "react-bootstrap-icons";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import { message } from "antd";
import { UserAuth } from "../Context";
import { saveInviteToDb } from "../lib/saveInviteToDb";
import { checkIfEmailExists } from "../lib/checkIfEmailExists";
import { Link } from "react-router-dom";
const { InviteUser } = require("../EmailSystem/sendEmails");

function Invite() {
  const { data, title, closeModal } = useModalStore();
  const [Error, setError] = useState("");
  const { user } = UserAuth();
  const [emailUsers, setEmailUsers] = useState([{ name: "", email: "" }]);
  const [phoneUsers, setPhoneUsers] = useState([{ cc: "", phone: "" }]);
  const text = "Invite Friends";
  const regex = /^[a-zA-Z\s]*$/;
  const myEmail = user?.providerData[0].email;
  const isFirstEmailUserEmpty =
    emailUsers[0].name === "" &&
    emailUsers[0].email === "" &&
    emailUsers.length === 1;
  const isFirstPhoneUserEmpty =
    phoneUsers[0].cc === "" &&
    phoneUsers[0].phone === "" &&
    phoneUsers.length === 1;
  const modified = data.replace(
    "http://localhost:3000",
    "https://lookflock.com"
  );
  const [inviteOption, setInviteOption] = useState("email");
  const onFinishPhone = (values) => {
    console.log("onFinishPhone values", values);
    // Logic to handle phone number invitations
    values.forEach(async (userData) => {
      const { name, cc, phone } = userData;
      console.log(`Invite to phone number: ${phone} for ${name}`);
      // Add the phone invite sending logic here
      await saveInviteToDb(
        {
          phone: `${cc}${phone}`,
          type: "phone",
        },
        user
      );
    });
    message.success("Invitations sent successfully!");
    closeModal();
  };
  const checkEmails = async () => {
    const emailSet = new Set();
    emailSet.add(myEmail);
    for (const person of emailUsers) {
      if (person.email === myEmail) {
        setError("Cannot email yourself");
        return false; // Email is same as user's email
      }
      if (emailSet.has(person.email)) {
        setError("Emails should be unique");
        return false; // Duplicate found
      }
      const emailExists = await checkIfEmailExists(person.email);
      if (emailExists.exists) {
        const handleLinkClick = () => {
          closeModal(); // Close the modal when the link is clicked
        };

        const userLink = (
          <Link
            to={`/userProfile/${emailExists.userId}`}
            onClick={handleLinkClick}
          >
            {emailExists.firstName}
          </Link>
        );

        // Set the error state with the clickable link
        setError(
          <>
            {person.email} is already on Lookflock. Add {userLink}.
          </>
        );

        return false;
      }

      emailSet.add(person.email);
    }
    return true;
  };
  const onEmailFinish = (values) => {
    values.forEach(async (userData) => {
      const { name, email } = userData;
      // console.log(name, email);
      InviteUser(user?.displayName, name, email, data).then((response) => {
        message.success({
          content: `Email sent successfully to ${email}`,
          key: "success",
        });
        closeModal();
      });
      // Save email invite to Firestore
      await saveInviteToDb(
        {
          name,
          email,
          type: "email",
        },
        user
      ).catch((error) => {
        message.error(`Error sending email to ${email}`);
        console.error("Error sending email:", error);
      });
    });
  };

  const handleCopyLink = () => {
    const fullLink = data.replace(
      "http://localhost:3000",
      "https://lookflock.com"
    );
    navigator.clipboard.writeText(fullLink); // Copy link to clipboard
    message.success({
      content: `Link copied`,
      key: "success",
    });
  };

  const handleFacebookShare = () => {
    const shareQuote = encodeURIComponent(text);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://lookflock.com/#/ProductDetails/${text}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${text}: ${data}`
      )}`
    );
  };
  const handleAddEmailUser = () => {
    setEmailUsers([...emailUsers, { name: "", email: "" }]);
  };

  const handleRemoveEmailUser = (index) => {
    const newEmailUsers = [...emailUsers];
    if (newEmailUsers.length > 1) {
      newEmailUsers.splice(index, 1);
      setEmailUsers(newEmailUsers);
    }
  };
  const handleEmailChange = (index, field, value) => {
    const newEmailUsers = [...emailUsers];
    setError("");
    newEmailUsers[index][field] = value;
    setEmailUsers(newEmailUsers);
  };
  const handleAddPhoneUser = () => {
    setPhoneUsers([...phoneUsers, { cc: "", phone: "" }]);
  };

  const handleRemovePhoneUser = (index) => {
    const newPhoneUsers = [...phoneUsers];
    newPhoneUsers.splice(index, 1);
    setPhoneUsers(newPhoneUsers);
  };

  const handlePhoneChange = (index, field, value) => {
    const newPhoneUsers = [...phoneUsers];
    newPhoneUsers[index][field] = value;
    setEmailUsers(newPhoneUsers);
  };
  return (
    <Modal show={true} centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Invite via email */}
        <ul className="nav nav-bottom-line">
          <li className="nav-item">
            <Link
              className={`nav-link ${inviteOption === "email" ? "active" : ""}`}
              onClick={() => setInviteOption("email")}
            >
              <span className="d-none d-sm-block">Invite by Email</span>
              <span className="d-block d-sm-none">Using Email</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${inviteOption === "link" ? "active" : ""}`}
              onClick={() => setInviteOption("link")}
            >
              <span className="d-none d-sm-block">Invite by Link</span>
              <span className="d-block d-sm-none">Copy link</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${inviteOption === "phone" ? "active" : ""}`}
              onClick={() => setInviteOption("phone")}
            >
              <span className="d-none d-sm-block">Invite by Phone number</span>
              <span className="d-block d-sm-none">Using Phone</span>
            </Link>
          </li>
        </ul>
        <div className="mt-4">
          {inviteOption === "email" ? (
            <div>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (await checkEmails()) {
                    onEmailFinish(emailUsers);
                  }
                }}
              >
                {emailUsers.map((user, index) => (
                  <InputGroup
                    key={index}
                    className="mb-1 d-flex justify-content-center align-items-center"
                  >
                    <Form.Control
                      className="me-1 rounded h-50"
                      placeholder="Name"
                      value={user.name}
                      onChange={(e) => {
                        if (regex.test(e.target.value)) {
                          handleEmailChange(index, "name", e.target.value);
                          setError("");
                        } else {
                          setError(
                            "Name cannot contain numbers or special characters"
                          );
                        }
                      }}
                      required
                    />
                    <Form.Control
                      className="ms-1 rounded h-50"
                      type="email"
                      placeholder="Email"
                      value={user.email}
                      onChange={(e) => {
                        handleEmailChange(index, "email", e.target.value);
                      }}
                      required
                    />
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={() => handleRemoveEmailUser(index)}
                      disabled={isFirstEmailUserEmpty}
                    >
                      <DashSquare className="fs-4" />
                    </button>
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={handleAddEmailUser}
                    >
                      {/* <PlusCircle type="submit" /> */}
                      <PlusSquare type="submit" className="fs-4 " />
                    </button>
                  </InputGroup>
                ))}
                <span className="ms-2 text-danger">{Error}</span>

                <div className="d-flex justify-content-end">
                  <Button
                    className="rounded d-flex align-items-center"
                    type="submit"
                    variant="primary-soft"
                    size="sm"
                  >
                    <EnvelopeCheck className="fs-6 me-2" />
                    <span>Invite</span>
                  </Button>
                </div>
              </Form>
            </div>
          ) : inviteOption === "phone" ? (
            <div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  onFinishPhone(phoneUsers);
                }}
              >
                {phoneUsers.map((user, index) => (
                  <InputGroup
                    key={index}
                    className="mb-3 flex justify-content-center align-items-center"
                  >
                    <Form.Control
                      type="number"
                      value={user.cc}
                      className="me-1 rounded h-50"
                      style={{ width: "0.5rem" }}
                      name="cc"
                      placeholder="+92"
                      onChange={(e) =>
                        handlePhoneChange(index, "cc", e.target.value)
                      }
                      required
                    />
                    <Form.Control
                      type="number"
                      className="mx-1 rounded h-50 w-50"
                      name="phone"
                      placeholder="Phone"
                      value={user.phone}
                      onChange={(e) =>
                        handlePhoneChange(index, "phone", e.target.value)
                      }
                    />
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={() => handleRemovePhoneUser(index)}
                      disabled={isFirstPhoneUserEmpty}
                    >
                      <DashSquare className="fs-4 " />
                    </button>
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={handleAddPhoneUser}
                    >
                      <PlusSquare className="fs-4 " />
                    </button>
                  </InputGroup>
                ))}
                <div className="d-flex justify-content-end">
                  <Button
                    className="rounded d-flex align-items-center"
                    type="submit"
                    variant="primary-soft"
                    size="sm"
                  >
                    <EnvelopeCheck className="fs-6 me-2" />
                    <span>Invite</span>
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            <div>
              <InputGroup className="mb-3">
                <Form.Control
                  className="rounded me-1 text-mute"
                  value={modified}
                  readOnly
                />
                <Button
                  className="rounded mx-1 "
                  variant="primary-soft"
                  size="sm"
                  onClick={handleCopyLink}
                >
                  <Link45deg className="fs-6 me-1" />
                  <span>Copy Link</span>
                </Button>
              </InputGroup>
              <p
                style={{
                  textAlign: "center",
                  color: "gray",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Anyone with this link can join Lookflock
              </p>
              <hr className="m-0" />
              <div className="d-flex justify-content-center m-0 pt-2">
                <div className="btn me-2" onClick={handleWhatsappShare}>
                  <Whatsapp style={{ fontSize: "28px", color: "#00CF08" }} />
                </div>
                <div className="btn" onClick={handleFacebookShare}>
                  <Facebook style={{ fontSize: "28px", color: "#0057DA" }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Invite;
