import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import { Link } from "react-router-dom";
import { onSnapshot, doc, collection, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import ShareMenu from "../Referral/ShareMenu";
import FriendRequestNotification from "../Notifications/NewFriendRequestNotification";
import Notification from "../Notifications/Notification";
import { useFetchUserData } from "../hooks/user/useFetchUserData";

function UserMenuHeader() {
  const { user, logOut } = UserAuth();  //state defined for theme
  const [theme, setTheme] = useState("");
  const [friendRequestslist, setRequestlist] = useState([]);
  const [totalFavoritesCount, setTotalFavorites] = useState(0);
  const [totalShoppingListCount, setTotalShoppingList] = useState(0);
  const { userData } = useFetchUserData(user?.uid);

  
  React.useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, [theme]);

  const signOutUser = async () => {
    logOut();
  };
  useEffect(() => {
    if (!user) {
      setTotalFavorites(0);
      setTotalShoppingList(0);
    }
  }, [user]);

  useEffect(() => {
    const fetchFavoritesCount = async () => {
      if (!user) return;

      const favoritesRef = collection(db, "users", user.uid, "favourites");
      try {
        const unsubscribeFavorites = onSnapshot(favoritesRef, (snapshot) => {
          const favoritesCount = snapshot.size; // Get the size of the snapshot, which represents the number of documents in the collection
          console.log("favoritesCount", favoritesCount);
          setTotalFavorites(favoritesCount);
        });

        return () => unsubscribeFavorites(); // Unsubscribe when the component unmounts
      } catch (error) {
        console.error("Error fetching favorites count:", error);
      }
    };

    const fetchShoppingListCount = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      try {
        const unsubscribeShoppingList = onSnapshot(userRef, (doc) => {
          const userData = doc.data();
          const shoppingListCount = userData?.shoppingListCount || 0;
          setTotalShoppingList(shoppingListCount);
        });

        return () => unsubscribeShoppingList(); // Unsubscribe when the component unmounts
      } catch (error) {
        console.error("Error fetching shopping list count:", error);
      }
    };

    fetchFavoritesCount();
    fetchShoppingListCount();
  }, [user]);
  const fetchCurrentUser = async () => {
    const loggedInUserRef = doc(db, "users", user.uid);
    const loggedUserDocSnapshot = await getDoc(loggedInUserRef);
    const requestlist = loggedUserDocSnapshot?.data()?.requestList || [];

    setRequestlist(requestlist);
  };

  // console.log('Hello from navigation friendrequestlist',friendRequestslist);

  // const renderAvatar = () => {
  //   const avatarContainerStyle = {
  //     marginRight: "10px",
  //   };

  //   if (userData) {
  //     const avatarInitials = userData.userName
  //       ? userData.userName[0].toUpperCase()
  //       : "";

  //     if (userData.photo) {
  //       const isUrl =
  //         userData.photo.startsWith("http") || userData.photo.startsWith("www");

  //       return (
  //         <div style={avatarContainerStyle} className="me-3 userHeaderAvatar">
  //           <Avatar
  //             name={avatarInitials}
  //             size={40}
  //             round={false}
  //             className="  rounded-2"
  //             src={
  //               isUrl
  //                 ? userData.photo
  //                 : `data:image/jpeg;base64,${userData.photo}`
  //             }
  //           />
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div
  //           style={avatarContainerStyle}
  //           className="me-3 nav-item ms-3 dropdown userHeaderAvatar "
  //         >
  //           <Avatar
  //             name={avatarInitials}
  //             size={40}
  //             round={false}
  //             className="bg-primary rounded-2 "
  //           />
  //         </div>
  //       );
  //     }
  //   }
  // };

  const handleTheme = (mode) => {
    setTheme(mode);
    document.documentElement.setAttribute("data-bs-theme", mode);
  };
  const handleOutsideClick = () => {
    const navbarCollapsible = document.getElementById("navbarCollapse");
    function fun() {
      if (navbarCollapsible.classList.contains("show"))
        navbarCollapsible.classList.remove("show");
    }
    setTimeout(fun, 400);
  };
  if (!userData) return;
  return (
    <>
      <li className="nav-item ms-2" onClick={() => handleOutsideClick()}>
        <Link
          className="nav-link icon-md btn btn-light p-0 shopping-list-link position-relative "
          to="/shoppingLists"
        >
          {totalShoppingListCount > 0 && (
            <span
              className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                totalShoppingListCount > 9 ? "px-1" : ""
              } bg-danger`}
              style={{
                top: "14%",
                left: "87%",
                fontSize: "10px",
                minHeight: "18px",
                minWidth: "18px",
              }}
            >
              {totalShoppingListCount}
            </span>
          )}
          <i className="bi bi-list-task fs-6"></i>
        </Link>
      </li>
      <span onClick={() => handleOutsideClick()}>
        <Notification user={user} />
      </span>

      <li
        className="nav-item ms-2 dropdown"
        onClick={() => handleOutsideClick()}
      >
        <Link
          className="nav-link btn icon-md p-0"
          id="profileDropdown"
          role="button"
          data-bs-auto-close="outside"
          data-bs-display="static"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <ProfilePicture
            userData={userData}
            className="avatar-img rounded-2"
          />
          {/* {renderAvatar()} */}
        </Link>
        <ul
          className="dropdown-menu dropdown-animation dropdown-menu-end pt-3 small me-md-n3"
          aria-labelledby="profileDropdown"
        >
          {/* Profile info */}
          <li className="px-3">
            <div className="d-flex align-items-center position-relative">
              {/* Avatar */}
              <div className="avatar me-3">
                <Link to={user ? `/userProfile/${user.uid}` : "/userProfile"}>
                  <ProfilePicture
                    userData={userData}
                    className="avatar-img rounded-2"
                  />
                </Link>
              </div>
              <div>
                <Link
                  className="h6 stretched-link text-nowrap"
                  to={user ? `/userProfile/${user.uid}` : "/userProfile"}
                >
                  {userData
                    ? userData.userName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : ""}
                </Link>
                <p className="small m-0">
                  <strong>{userData ? userData.userTitle : ""}</strong>
                </p>
              </div>
            </div>
            <Link
              className="dropdown-item btn btn-primary-soft btn-sm my-2 text-center rounded-1"
              to={user ? `/userProfile/${user.uid}` : "/userProfile"}
            >
              View profile
            </Link>
            <ShareMenu />
          </li>

          <li className="dropdown-divider"></li>
          {/* <FriendRequestNotification requestlist={friendRequestslist} loggedInUser={user}/> */}
          <li>
            <Link
              to="/SignIn"
              className="dropdown-item bg-danger-soft-hover"
              onClick={signOutUser}
            >
              <i className="bi bi-power fa-fw me-2"></i>Sign Out
            </Link>
          </li>
          <li>
            {" "}
            <hr className="dropdown-divider" />
          </li>

          {/* Dark mode options START */}
          <li>
            <div className="modeswitch-item theme-icon-active d-flex justify-content-center gap-3 align-items-center p-2 pb-0">
              <span>Mode:</span>
              <button
                type="button"
                className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                  theme === "light" ? "active" : ""
                }`}
                data-bs-theme-value="light"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Light"
                onClick={() => {
                  handleTheme("light");
                  localStorage.setItem("theme", "light");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-sun fa-fw mode-switch"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                  <use href="#"></use>
                </svg>
              </button>
              <button
                type="button"
                className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                  theme === "dark" ? "active" : ""
                }`}
                data-bs-theme-value="dark"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Dark"
                onClick={() => {
                  handleTheme("dark");
                  localStorage.setItem("theme", "dark");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-moon-stars fa-fw mode-switch"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                  <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z" />
                  <use href="#"></use>
                </svg>
              </button>
              <button
                type="button"
                className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                  theme === "" || theme === "auto" ? "active" : ""
                }`}
                data-bs-theme-value="auto"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Auto"
                onClick={() => {
                  handleTheme("auto");
                  localStorage.setItem("theme", "auto");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-circle-half fa-fw mode-switch"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" />
                  <use href="#"></use>
                </svg>
              </button>
            </div>
          </li>
          {/* Dark mode options END*/}
          {/* 
          <hr />
          <li className="text-body px-3">
            <Link
              to="/TandC"
              className="text-body text-center p-1 mb-2 rounded-1 bg-light dropdown-item"
            >
              Terms of Services
            </Link>
            <Link
              to="/PrivacyPolicy"
              className="text-body text-center p-1 my-2 rounded-1 bg-light dropdown-item"
            >
              Privacy Policy
            </Link>
            <Link
              to="/contactUs"
              className="text-body text-center p-1 mt-2 rounded-1 bg-light dropdown-item"
            >
              Contact Us
            </Link>
          </li> 
          */}
        </ul>
      </li>
    </>
  );
}

export default UserMenuHeader;
