import React, { useEffect, useRef, useState } from "react";

import { UserAuth } from "../Context";

import useModalStore from "../StatesStore/ModalStore";
import { dataNewsFeed, titleNewsFeed } from "../Content/ModalContent";

import ProductCard3 from "../Products/ProductCard3";
import ShareFeed from "./ShareFeed";
import { useInView } from "react-intersection-observer";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { XMasonry, XBlock } from "react-xmasonry";
import UnderAgeWarning from "../Notifications/UnderAgeWarning";
import { Link } from "react-router-dom";
import UserCard from "../Cards/UserCard";
import NotificationOnFeed from "./NotificationOnFeed";
import ShoppingListPostCard from "../UserPosts/ShopPost/ShoppingListPostCard";
import TextOnlyPostCard from "../UserPosts/TextOnlyPost/TextOnlyPostCard";
import WhoToFollow from "../Followers/WhoToFollow";
import RecentlyJoinedUsers from "../components/RecentlyJoinedUsers";
// import GoogleAd from "../Cards/GoogleAd";

export default function NewsFeed4() {
  const { user } = UserAuth();
  const linkRef = useRef(null);
  const { openModal } = useModalStore();
  const [columnCount, setColumnCount] = useState(getColumnCount());
  const [lastSeenFeed, setLastSeenFeed] = useState(null);

  const [newsFeedItems, setNewsFeedItems] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [feedCount, setFeedCount] = useState(null);
  const [count, setPoCount] = useState(null);
  const loadMoreRef = useRef(null);

  // const fetchProductLog = async () => {
  //   const response = await fetch("http://localhost:8082/api/productLog/P5VKfm6w4hZShp7hKOIXhpjgfJp1");
  //   if (!response.ok) {
  //     throw new Error('Network response was not ok');
  //   }
  //   const data = await response.json();
  //   return data;
  // };

  const defaultFeed = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/api/logoutFeed`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };

  const { data, error } = useQuery({
    queryKey: ["defaultFeed"],
    queryFn: () => defaultFeed(),
    enabled: !user, // Query runs when userId is undefined
  });
  // console.log("data is",data);
  const generateFeed = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/newsFeedProducts2/${user?.uid}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };

  //   useQuery({
  //   queryKey: ["Feed",user?.uid],
  //   queryFn: () => generateFeed(),
  //   refetchInterval:5000,
  //   refetchOnWindowFocus: false,
  //   enabled: feedCount<200 && !!user, // Query runs when userId is undefined
  // });

  // const { data:ffe,  } = useQuery({
  //   queryKey: ['productLog'],
  //   queryFn:fetchProductLog,
  //   placeholderData:keepPreviousData

  // });

  // useEffect(()=>{
  //   if(ffe){
  //     console.log("herer",ffe);
  //     setNewsFeedItems((prevItems) => [...ffe, ...prevItems]);
  //     // setNewsFeedItems((prevItems) => shuffleItems([...ffe, ...prevItems]));
  //   }
  // },[ffe])
  useEffect(() => {
    if (!user?.uid) return;

    const userDocRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        console.log("The post count here is ", userData.feedCount);
        setFeedCount(userData.feedCount || 0); // Directly set the latest feedCount
      }
    });

    // Clean up function to stop listening for updates when the component unmounts
    return () => unsubscribe();
  }, [user?.uid]);

  const checkNewsFeed2Empty = async () => {
    const userDocRef = doc(db, "users", user?.uid);
    const newsFeed2Ref = collection(userDocRef, "newsFeed2");
    const newsFeed2Snapshot = await getDocs(newsFeed2Ref);
    console.log("Newfeed is", newsFeed2Snapshot.empty);
    return newsFeed2Snapshot.empty;
  };

  const updateLastSeenFeed = async () => {
    const userDocRef = doc(db, "users", user?.uid);
    const userDoc = await getDoc(userDocRef);
    let startIndex = 0;
    let viewedIndex = 0;

    if (userDoc.exists()) {
      const userData = userDoc.data();
      let lastSeenFeedTimestamp = userData.lastSeenFeed?.toDate();
      console.log("lastSeenFeedTimestamp", userData.lastSeenFeed);
      let timestamp = userData?.lastSeenFeed;
      viewedIndex = userData?.viewedIndex || 0;
      console.log("The viewed index is", viewedIndex);
      startIndex = userData?.startIndex || 0;
      console.log("The viewed index is", startIndex);
      // Check if lastSeenFeedTimestamp is defined
      if (lastSeenFeedTimestamp !== undefined) {
        const currentTime = new Date().getTime();
        console.log("currentTime", currentTime);

        // Use ternary operator to safely access .toUTCString()
        const utcLastSeenFeedTimestamp = lastSeenFeedTimestamp.toUTCString()
          ? lastSeenFeedTimestamp.getTime()
          : lastSeenFeedTimestamp;
        console.log("utcLastSeenFeedTimestamp", utcLastSeenFeedTimestamp);

        const utcCurrentTime = currentTime;
        console.log(
          "utcCurrentTime - utcLastSeenFeedTimestamp",
          utcCurrentTime - utcLastSeenFeedTimestamp
        );
        console.log("currentTime", currentTime);
        const lastSeenFeedMillis = lastSeenFeedTimestamp.getTime();
        console.log("lastSeenFeedMillis", lastSeenFeedMillis);
        console.log(
          "(currentTime - lastSeenFeedMillis)",
          currentTime - lastSeenFeedMillis
        );
        if (Math.abs(currentTime - lastSeenFeedMillis) > 2 * 60 * 60 * 1000) {
          console.log(
            "(currentTime - lastSeenFeedMillis)",
            currentTime - lastSeenFeedMillis
          );
          setNextCursor(null);
          setLastSeenFeed(true);
          startIndex = viewedIndex;
          await setDoc(
            userDocRef,
            { lastSeenFeed: serverTimestamp(), startIndex },
            { merge: true }
          );
          return startIndex;
        } else {
          setLastSeenFeed(false);
          return startIndex;
        }
      } else {
        console.error("lastSeenFeedTimestamp is undefined");
        await setDoc(
          userDocRef,
          { lastSeenFeed: serverTimestamp(), startIndex },
          { merge: true }
        );
        setLastSeenFeed(false);
        return startIndex;
      }
    } else {
      // await setDoc(userDocRef, { lastSeenFeed: serverTimestamp() }, { merge: true });
      setLastSeenFeed(false);
      return startIndex;
    }
  };

  const fetchNewsFeed = async (cursor = null) => {
    if (!user) return;

    setLoading(true);
    let startIndex = await updateLastSeenFeed();

    console.log("viewedIndex aes", startIndex);
    try {
      const params = new URLSearchParams();
      if (cursor) {
        params.append("startAfter", cursor);
      }
      params.append("limit", 25);
      params.append("startIndex", startIndex);

      const response = await fetch(
        `${process.env.REACT_APP_API}/api/newsFeedProductsPagination/${
          user?.uid
        }?${params.toString()}`
      );
      const data = await response.json();

      const { newsFeedItems: newItems, nextCursor: newCursor } = data;
      if (!nextCursor) {
        setNewsFeedItems(newItems);
      } else {
        setNewsFeedItems((prevItems) => [...prevItems, ...newItems]);
      }

      // setNewsFeedItems((prevItems) => shuffleItems([...prevItems, ...newItems]));

      setNextCursor(newCursor);
      //     const userDocRef = doc(db, "users", user?.uid);
      //     const userDoc = await getDoc(userDocRef);

      // if (userDoc.exists()) {
      //   const userData = userDoc.data();
      //   console.log("THe post count here is ",userData.feedCount);
      //   setfeedCount(userData.feedCount || 0);
      // }
      // await setDoc(userDocRef, { lastSeenFeed: serverTimestamp() }, { merge: true });
      // await updateLastSeenFeed();
    } catch (error) {
      console.error("Error fetching news feed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialNewsFeed = async () => {
    setLoading(true);
    try {
      let url1;
      let url2;

      url1 = `${process.env.REACT_APP_API}/api/newsFeedProducts2/${user?.uid}`;

      url2 = `${process.env.REACT_APP_API}/api/newsFeedProductsPagination/${user?.uid}`;

      //  await fetch(url1);
      await fetch(url1);

      const response = await fetch(url2);
      const data = await response.json();
      const { newsFeedItems: newItems, nextCursor: newCursor } = data;
      setNewsFeedItems(newItems);
      setNextCursor(newCursor);
      //   const userDocRef = doc(db, "users", user?.uid);
      //     const userDoc = await getDoc(userDocRef);

      // if (userDoc.exists()) {
      //   const userData = userDoc.data();
      //   setfeedCount(userData.feedCount || 0);
      // }
    } catch (error) {
      console.error("Error fetching initial news feed:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("The post count is ", feedCount);

  useEffect(() => {
    const func = async () => {
      if (user) {
        const isNewsFeed2Empty = await checkNewsFeed2Empty();
        if (isNewsFeed2Empty) {
          fetchInitialNewsFeed();
        } else {
          fetchNewsFeed();
        }
      }
      // await setDoc(userDocRef, { lastSeenFeed: serverTimestamp() }, { merge: true });
    };
    func();
  }, [user]);

  console.log("the newsFeedItems are", newsFeedItems);
  // console.log("the newsFeedItems are", newsFeedItems);

  useEffect(() => {
    if (loading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && nextCursor) {
          fetchNewsFeed(nextCursor);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading, nextCursor]);

  const { ref: myRef, inView } = useInView({
    threshold: 0.1,
  });

  // const { ref: loadMoreRef, inView:loadMoreView } = useInView({

  //   threshold: 0,
  // });
  const { ref: loadMoreRef2, inView: loadMoreView2 } = useInView({
    threshold: 0.1,
  });
  const { ref: loginRef, inView: modalView } = useInView({
    threshold: 1,
    // delay :5000,
    rootMargin: "-170px",
  });
  // const { ref: loadRef, inView:loadView } = useInView({

  //   threshold: 0.00001,
  // });

  useEffect(() => {
    function handleResize() {
      setColumnCount(getColumnCount());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const func = async () => {
      let url;

      url = `${process.env.REACT_APP_API}/api/newsFeedProducts2/${user?.uid}`;
      console.log("Fetching feed");
      // await fetch(url);
      await fetch(url);

      fetchNewsFeed(nextCursor);
      //   const data = await response.json();
    };
    if (inView) {
      func();
    }
  }, [inView]);
  // useEffect(() => {

  //   if (loadMoreView) {
  //     fetchNewsFeed(nextCursor);
  //   }
  // }, [loadMoreView]);
  useEffect(() => {
    if (loadMoreView2) {
      fetchNewsFeed(nextCursor);
    }
  }, [loadMoreView2]);
  useEffect(() => {
    if (modalView) {
      const closable = true;

      // Open the modal and pass data
      openModal("SignInModal", titleNewsFeed, dataNewsFeed, closable);
    }
  }, [modalView]);
  // useEffect(() => {
  //   const func = async()=>{
  //       let url;
  //       const params = new URLSearchParams();
  //     if (nextCursor) {
  //       params.append("startAfter", nextCursor);
  //     }

  //       url = `http://localhost:8082/api/newsFeedProducts2/${user?.uid}?${params.toString()}`;

  //       const response = await fetch(url);
  //     const data = await response.json();
  //   //   const data = await response.json();
  //   // const { combinedPosts: newItems } = data;

  //  console.log("Direct feed is",data);
  //       setNewsFeedItems((prevItems) => [...prevItems, ...data]);
  //   }
  //   if (loadView) {
  //       func();
  //   }
  // }, [loadView]);

  function getColumnCount() {
    const screenWidth = window.innerWidth;
    let count = 2;
    if (screenWidth >= 440) {
      count = 3;
    }
    if (screenWidth >= 768) {
      count = 4;
    }
    if (screenWidth >= 900) {
      count = 5;
    }
    return count;
  }

  //   if (loading) {
  //     return (
  //       <div
  //         className="d-flex justify-content-center align-items-center"
  //         style={{ height: "100vh" }}
  //       >
  //         <div className="spinner-border" role="status">
  //           <span className="visually-hidden">Loading...</span>
  //         </div>
  //       </div>
  //     );
  //   }

  console.log("Ït is in view", inView);
  console.log("Ït is in data", data);
  // // console.log("Ït is in loadMoreView", loadMoreView);
  // console.log("Ït is in loadMoreView2", loadMoreView2);
  // console.log("Ït is in loginRef", modalView);

  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }
  return (
    <>
      <XMasonry
        center={false}
        responsive={true}
        maxColumns={5}
        targetBlockWidth={getTargetBlockWidth()}
      >
        <XBlock>{user && <ShareFeed />}</XBlock>
        {/* <XBlock width={2}>{user && <ShoppingListPostCard />}</XBlock> */}
        {/* <XBlock width={1}>{user && <TextOnlyPostCard />}</XBlock> */}
        {/* <XBlock width={2}>{user && <NotificationOnFeed />}</XBlock> */}
        {/* <XBlock width={1}>{user && <RecentlyJoinedUsers />}</XBlock> */}
        {(user ? newsFeedItems : data)?.map((post, index) => {
          // if (index === 3) {
          //   return (
          //     <XBlock>
          //       <GoogleAd />
          //     </XBlock>
          //   );
          // }
          if (user && index === 1) {
            return (
              <XBlock width={2}>
                <NotificationOnFeed />
              </XBlock>
            );
          }
          if (user && index === 5) {
            return (
              <XBlock>
                <RecentlyJoinedUsers />
              </XBlock>
            );
          }
          if (!user && index === 40) {
            return (
              <XBlock>
                <div ref={loginRef}></div>
              </XBlock>
            );
          }
          if (user && index === Math.floor(feedCount * 0.7)) {
            return (
              <XBlock>
                <div ref={myRef}></div>
              </XBlock>
            );
          }
          // {user && index === Math.floor(feedCount * 0.7) && (<div ref={myRef}></div>)}
          {
            index === newsFeedItems.length - 15 && (
              <div ref={loadMoreRef2}></div>
            );
          }

          if (post.advertised) {
            return (
              <XBlock width={2}>
                {/* <TestPromotionCard post={post} /> */}
                <ProductCard3 key={post.id} product={post} />
              </XBlock>
            );
          } else if (post.by === "user") {
            return (
              <XBlock width={2}>
                {/* <TestPromotionCard post={post} /> */}
                <UserCard key={post.id} post={post} />
              </XBlock>
            );
          } else {
            return (
              <XBlock>
                <ProductCard3 key={post.id} product={post} />
              </XBlock>
            );
          }
        })}
      </XMasonry>
      {!user && data && (
        <div
          className="alert alert-success alert-dismissible fade show mb-0"
          role="alert"
        >
          <strong>
            Please <Link to="/SignIn">sign in</Link> or{" "}
            <Link to="/SignUp">register</Link>
          </strong>{" "}
          to continue browsing the newsfeed and other functionality
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}

      <div ref={loadMoreRef}></div>
      {/* {!user  && data &&<div ref={loginRef}></div>} */}

      {loading && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}
