import { writeBatch, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import useNotification from "../hooks/useNotification";

export const SendFriendRequest = async (senderId, receiverId) => {
  const { addNotification } = useNotification();
  const isCurrentUserProfile = senderId === receiverId;

  if (!senderId || isCurrentUserProfile) {
    console.log("Cannot add friend for own profile or no senderId provided.");
    return;
  }

  try {
    // Start a batched write to perform multiple updates atomically
    const batch = writeBatch(db);

    const timestamp = serverTimestamp(); // Get current timestamp

    // Get references to the connections subcollection for the sender and receiver
    const senderConnectionRef = doc(
      db,
      "users",
      senderId,
      "connections",
      receiverId
    );
    const receiverConnectionRef = doc(
      db,
      "users",
      receiverId,
      "connections",
      senderId
    );

    // Add the document for the sender with type "request"
    batch.set(senderConnectionRef, {
      type: "request",
      timestamp: timestamp,
    });

    // Add the document for the receiver with type "pending"
    batch.set(receiverConnectionRef, {
      type: "pending",
      timestamp: timestamp,
    });

    // Commit the batched write
    await batch.commit();

    await addNotification({
      userId: receiverId,
      id: senderId,
      type: "friendRequest",
      by: "user",
    });

    console.log("Friend request sent successfully!");
  } catch (error) {
    console.error("Error sending friend request:", error);
  }
};

export default SendFriendRequest;
