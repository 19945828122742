import React, { useEffect } from "react";
import { db } from "../FirebaseConfig"; // Firestore config
import { doc, setDoc, Timestamp } from "firebase/firestore"; // Firestore imports

function UpdateLastLogin({ userID }) {
  useEffect(() => {
    const updateLastLogin = async () => {
      try {
        const userDocRef = doc(db, "lastLogin", userID); // Reference to document with userID
        // Update the document with the current timestamp
        await setDoc(userDocRef, {
          lastLogin: Timestamp.now(),
        }, { merge: true }); // Use merge to avoid overwriting existing data
        console.log(`Updated last login timestamp for user ${userID}`);
      } catch (error) {
        console.error("Error updating last login:", error);
      }
    };

    if (userID) {
      updateLastLogin();
    }
  }, [userID]);

  return null; // No UI needed for this utility function
}

export default UpdateLastLogin;
