/* global GLightbox */
import React, { useEffect, useState } from "react";
import UserCard from "../UserProfile/UserDisplay";
import FeedReaction from "./FeedReaction";
import GlightImage from "./GlightImage";
import FeedDropdown from "./FeedDropdown";
import sanitizeHtml from "sanitize-html";
import UserGlightbox from "../components/UserGlightbox";
import BrandFeedDropdown from "./BrandFeedDropdown";
import BrandCard from "./BrandDisplay";
import { UserAuth } from "../Context";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import UserDisplay from "../UserProfile/UserDisplay";

export default function OneItemNews({ post }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = UserAuth();
  const maxCharactersPerLine = 67; // Adjust based on your font size and container width
  const maxLines = 3;
  const maxCharacters = maxCharactersPerLine * maxLines;
  const clean = sanitizeHtml(post?.title || "", {
    allowedTags: ["br"],
    disallowedTagsMode: "discard",
  });

  const { userData } = useFetchUserData(post?.userId);

  useEffect(() => {
    const lightbox = GLightbox({
      selector: '[data-postglightbox=""]',
    });

    return () => {
      lightbox.destroy();
    };
  }, [post]);
  console.log("User Data is", userData);
  return (
    <>
      {/* Card feed item START */}
      <div className="card">
        {/* Card header START */}
        <div className="card-header border-0 pb-0">
          <div className="d-flex align-items-center justify-content-between">
            {userData && <UserDisplay userData={userData} post={post} />}

            {!userData && <BrandCard post={post} />}

            {!userData && user && user.uid && <BrandFeedDropdown post={post} />}
            {/* Card share action START */}
            {/* {userData && <FeedDropdown post={post} />} */}
          </div>
        </div>
        {/* Card header END */}
        {/* Card body START */}
        <div className="card-body">
          <div style={{ whiteSpace: "pre-wrap" }}>
            {isExpanded ? (
              <div dangerouslySetInnerHTML={{ __html: clean }} />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: clean?.slice(0, maxCharacters),
                }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            )}
            {!isExpanded && post?.title?.length > maxCharacters && (
              <>
                <div
                  onClick={() => setIsExpanded(true)}
                  style={{
                    color: "#477ded",
                    cursor: "pointer",
                    paddingBottom: "12px",
                  }}
                >
                  ...see more  
                </div>
              </>
            )}
          </div>

          {post?.images?.map((image, index) =>
            post?.by === "user" ? (
              <UserGlightbox
                key={index}
                post={post}
                image={image}
                imageIndex={index}
              />
            ) : (
              <GlightImage
                key={index}
                post={post}
                image={image}
                imageIndex={index}
              />
            )
          )}

          {post?.id && <FeedReaction post={post} />}
        </div>
        {/* Card body END */}
      </div>
      {/* Card feed item END */}
    </>
  );
}
