import { collection, doc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { clearAllStoredReferralCodes, getAllStoredReferralCodes } from "./storedReferralCode";
import { db } from "../FirebaseConfig";

const addNotification = async (userId, currentUserId) => {
    try {
        const notificationRef = doc(collection(db, `users/${userId}/notifications`));
        await setDoc(notificationRef, {
            id: currentUserId,
            timestamp: serverTimestamp(),
            type: "joined",
            seen:false,
        });
        console.log(`Notification added for user: ${userId}`);
    } catch (error) {
        console.error(`Error adding notification for user ${userId}:`, error);
    }
};


export const handleReferralCodes = async (currentUserId) => {
    const referralCodes = getAllStoredReferralCodes();
    console.log('Referral Codes to be sent func:', referralCodes);

    for (const referralCode of referralCodes) {
        const q = query(collection(db, 'users'), where('referralCode', '==', referralCode));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userId = docSnapshot.id;
                await addNotification(userId, currentUserId);
            }
        });
    }
    clearAllStoredReferralCodes()
};