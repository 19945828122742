import React, { useEffect, useState, useRef } from "react";
import useUserStore from "../StatesStore/UserStore"; // Import the Zustand store
import calculateAge from "../lib/calculateAge";
import { useNavigate } from "react-router-dom";
import { toastOptions } from "../lib/toastOptions";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  serverTimestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import TermsAndConditions from "./TermsAndConditions";
// import { DatePicker } from "antd";
import WebFont from "webfontloader";
import Select from "react-select";
import GetLocation from "./GetLocation";
import { UserAuth } from "../Context";
import dayjs from "dayjs";
import { handleReferralCodes } from "../lib/handleReferralCodes";
import { DatePicker } from "react-date-picker";
//More Questions
export default function SignInInput() {
  const one =
    process.env.PUBLIC_URL + "/assets/images/moreQuestionsHeaders/1.svg";
  const two =
    process.env.PUBLIC_URL + "/assets/images/moreQuestionsHeaders/2.svg";
  const three =
    process.env.PUBLIC_URL + "/assets/images/moreQuestionsHeaders/3.svg";
  const four =
    process.env.PUBLIC_URL + "/assets/images/moreQuestionsHeaders/4.svg";
  const five =
    process.env.PUBLIC_URL + "/assets/images/moreQuestionsHeaders/5.svg";
  const six =
    process.env.PUBLIC_URL + "/assets/images/moreQuestionsHeaders/6.svg";
  // Variable to change state
  const [stage, setStage] = useState(1);
  // Values which will be submitted
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [location, setLocation] = useState("");
  const [brands, setBrands] = useState([]); //This will have all the primary categories
  const [dropDownbrands, setDropDownBrands] = useState(null); //This will have all the primary categories
  const [allBrands, setAllBrands] = useState([]); // Updated to use state for allBrands  //this will contain top 7 brands
  const [famBrands, setFamBrands] = useState([]); //this will have all the brands which are going to database
  const [categories, setCategories] = useState([]);
  const [primaryCategories, setPrimaryCategories] = useState({}); //This will have all the secondary
  const [selectedOption, setSelectedOption] = useState("");
  const [visibleCategories, setVisibleCategories] = useState({
    "Ready to Wear": false,
    "Western Wear": false,
    Unstitched: false,
  });
  const options = [
    { id: "search-engine", value: "Search Engine", label: "Search Engine" },
    { id: "social-media", value: "Social Media", label: "Social Media" },
    { id: "college-visit", value: "College Visit", label: "College Visit" },
    { id: "friends-family", value: "Friends/Family", label: "Friends/Family" },
    { id: "others", value: "Others", label: "Others" },
  ];

  // Error variables
  const [birthdayError, setBirthdayError] = useState("");

  const menuPortalTarget = useRef(null); //for sizing of select component
  const userStoreUser = useUserStore((state) => state.user);
  const { user } = UserAuth();
  const navigate = useNavigate();

  const handleNext = () => {
    setStage(stage + 1);
  };
  // Gender
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleSubmitGender = (e) => {
    e.preventDefault();
    userStoreUser.gender = gender;
    handleNext();
  };
  // Birthday
  const handleChangeBirthday = (date) => {
    setBirthdayError("");
    console.log(date.toLocaleDateString("en-us"));
    setBirthday(date);
  };
  const handleSubmitBirthday = (e) => {
    e.preventDefault();
    if (birthday !== "") {
      let modifiedBirthday = birthday.toLocaleDateString("en-us");
      const age = calculateAge(birthday);
      if (age < 13) {
        console.log(
          "You must be at least 13 years old to sign in to Lookflock"
        );
        setBirthdayError(
          "You must be at least 13 years old to sign in to Lookflock!"
        );
        return;
      } else {
        userStoreUser.birthday = modifiedBirthday;
        userStoreUser.isVerified = true;
        handleNext();
      }
    } else {
      setBirthdayError("Please enter your birthday first!");
    }
  };
  // Location
  const handleLocationChange = (newLocation) => {
    setLocation(newLocation);
  };
  const handleSubmitLocation = (e) => {
    e.preventDefault();
    handleNext();
  };
  // BRANDS
  //top brands handlers
  const handleBrandClick = (brand) => {
    // If brand is already added, remove it; otherwise, add it
    if (brands.includes(brand.name)) {
      setBrands((prevBrands) =>
        prevBrands.filter((item) => item !== brand.name)
      );
    } else {
      setBrands((prevBrands) => [...prevBrands, brand.name]);
    }
  };
  //fetch brands function
  const fetchBrands = async () => {
    try {
      const brandsCol = collection(db, "brands");
      const brandSnapshot = await getDocs(brandsCol);
      let brandsArray = [];
      brandSnapshot.forEach((doc) => {
        let brand = doc.data();
        brandsArray.push({
          value: brand.displayName,
          label: brand.displayName,
          like: brand.like,
          name: brand.name,
          category: brand.category[0],
        });
      });
      // Sort brands based on 'like' values
      brandsArray.sort((a, b) => b.like - a.like);

      //filter top 7 same brands
      const filteredBrands = [];
      //   console.log("GenderData", gender);
      for (
        let i = 0;
        i < brandsArray.length && filteredBrands.length < 7;
        i++
      ) {
        const brand = brandsArray[i];
        const category = brand.category === "Men" ? "male" : "female";
        if (category === gender) {
          filteredBrands.push(brand);
        }
      }
      //   console.log("Filtered brands", filteredBrands);
      // Add top 7 brands to famBrands array and the rest to allBrands array
      const topBrands = filteredBrands.map((brand) => ({
        value: brand.value,
        label: brand.label,
        name: brand.name,
      }));

      //function to include values that are not in top brands to show in dropdown
      const restBrands = brandsArray
        .filter((brand) => !filteredBrands.includes(brand))
        .map((brand) => ({
          value: brand.value,
          label: brand.label,
          name: brand.name,
        }));
      console.log("restBrands", restBrands);
      setFamBrands(topBrands);
      setAllBrands(restBrands);
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  const handleChangeBrand = (selected) => {
    setDropDownBrands(selected);
  };
  const handleSubmitBrands = (e) => {
    e.preventDefault();
    if (dropDownbrands !== null) {
      const newBrands = dropDownbrands.map((brand) => brand.name);
      console.log("brands", brands);
      console.log("newBrands", newBrands);
      setBrands([...brands, ...newBrands]);
    }
    handleNext();
  };
  // CATEGORIES
  const fetchCategories = async () => {
    try {
      const categoriesCol = collection(db, "universalCategories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      let genderArray = [];
      categoriesSnapshot.forEach((doc) => {
        let GenderData = doc.data();
        genderArray.push(GenderData);
      });
      // console.log("GenderData", genderArray);
      let maleCategories = genderArray[0].subCategories;
      // console.log("Male", maleCategories);
      let femaleCategories = genderArray[1].subCategories;
      // console.log("Female", femaleCategories);
      if (gender === "male") {
        setPrimaryCategories(maleCategories);
        // setSecondaryCategories(femaleCategories);
      } else if (gender === "female") {
        setPrimaryCategories(femaleCategories);
      }
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  //category handlers
  const handleCategoryClick = (categoryKey, category) => {
    // console.log(categoryKey, category);
    let select = {};
    select[categoryKey] = category;
    // console.log(select);
    // Check if the category is already added
    const isAlreadyAdded = categories.some((item) =>
      areObjectsEqual(item, categoryKey, category)
    );
    //If added then remove
    if (isAlreadyAdded) {
      setCategories((prevCategory) =>
        prevCategory.filter(
          (item) => !areObjectsEqual(item, categoryKey, category)
        )
      );
    } else {
      setCategories((prevCategory) => [...prevCategory, select]);
    }
  };
  //Check if both objects are equal
  const areObjectsEqual = (obj1, categoryKey, category) => {
    let obj2 = {};
    obj2[categoryKey] = category;
    // console.log(obj2)
    return (
      Object.keys(obj1).length === Object.keys(obj2).length &&
      Object.keys(obj1).every((key) => obj1[key] === obj2[key])
    );
  };
  const toggleCategoryVisibility = (categoryKey) => {
    // console.log(visibleCategories)
    setVisibleCategories((prevState) => ({
      ...prevState,
      [categoryKey]: !prevState[categoryKey],
    }));
  };
  const handleSubmitCategories = (e) => {
    e.preventDefault();
    handleNext();
  };
  // How did you hear about us
  const handleOptionClick = (value) => {
    setSelectedOption(value);
  };
  const handleSubmission = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = doc(db, "users", user?.uid);
      const dataToUpdate = {
        gender: gender,
        birthday: birthday.toLocaleDateString("en-us"),
        location: location,
        favBrands: brands,
        favCategories: categories,
        heardAboutUs: selectedOption,
      };
      await updateDoc(userDocRef, dataToUpdate);
      // Go through the list of favBrands
      for (const brandName of brands) {
        // Get the brand document reference
        const brandDocRef = doc(db, "brands", brandName);

        // Add the user to the 'followers' subcollection in the brand document
        const followersDocRef = doc(brandDocRef, "followers", user.uid);
        await setDoc(followersDocRef, {
          dateCreated: serverTimestamp(),
        });

        // Get the user's document reference
        const userDocRef = doc(db, "users", user.uid);

        // Add a document in the user's subcollection with the brand name
        const userBrandDocRef = doc(userDocRef, "following", brandName);
        await setDoc(userBrandDocRef, {
          timestamp: serverTimestamp(),
          type: "brand",
        });
      }
      handleReferralCodes(user.uid);
      // Navigate to the desired location
      navigate("/");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
  useEffect(() => {
    if (!user) return;
    const checkDataToSelectStage = async () => {
      const userRef = doc(db, "users", user?.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      // console.log(userData);
      // console.log("userData.gender:", userData.gender);
      // console.log("userData.birthday:", userData.birthday);
      // console.log("userData.location:", userData.location);
      // console.log("userData.favBrands:", userData.favBrands);
      // console.log("userData.favCategories:", userData.favCategories);
      // console.log("userData.heardAboutUs:", userData.heardAboutUs);
      setGender(userData.gender || "");
      setBirthday(userData.birthday || "");
      setLocation(userData.location || "");
      setBrands(userData.favBrands || []);
      setCategories(userData.favCategories || []);
      setSelectedOption(userData.heardAboutUs || "");
      if (userData.gender === null) setStage(1);
      else if (userData.birthday === null) setStage(2);
      else if (userData.location === "" || userData.location === null)
        setStage(3);
      else if (userData.favBrands.length === 0) setStage(4);
      else if (userData.favCategories.length === 0) setStage(5);
      else if (userData.heardAboutUs === "" || userData.heardAboutUs === null)
        setStage(6);
      else navigate("/");
    };
    checkDataToSelectStage();
  }, [user]);
  //Load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
  }, []);
  useEffect(() => {
    if (gender === "") return;
    const fetchData = async () => {
      //function call
      await fetchBrands();
      //function call
      await fetchCategories();
    };
    fetchData();
    // eslint-disable-next-line
  }, [gender]);
  useEffect(() => {
    console.log("gender: ", gender);
  }, [gender]);
  useEffect(() => {
    console.log("birthday: ", birthday);
  }, [birthday]);
  useEffect(() => {
    console.log("location: ", location);
  }, [location]);
  useEffect(() => {
    console.log("brands: ", brands);
  }, [brands]);
  useEffect(() => {
    console.log("categories: ", categories);
  }, [categories]);
  useEffect(() => {
    console.log("selectedOption: ", selectedOption);
  }, [selectedOption]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100 py-4">
          <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
            <div className="card card-body text-center rounded-3 p-1 px-sm-4 p-sm-4">
              <div className="text-center">
                {stage === 1 && (
                  <div>
                    <img src={one} alt="" width={"70%"} />
                    <p className="mt-3 fs-4 grey">What is your gender?</p>
                  </div>
                )}
                {stage === 2 && (
                  <div>
                    <img src={two} alt="" width={"70%"} />
                    <p className="mt-3 fs-4 grey">When is your birthday?</p>
                  </div>
                )}
                {stage === 3 && (
                  <div>
                    <img src={three} alt="" width={"70%"} />
                    <p className="mt-3 fs-4 grey">Where do you live?</p>
                  </div>
                )}
                {stage === 4 && (
                  <div>
                    <img src={four} alt="" width={"70%"} />
                    <p className="mt-3 fs-4 grey">
                      What are your favourite brands?
                    </p>
                  </div>
                )}
                {stage === 5 && (
                  <div>
                    <img src={five} alt="" width={"70%"} />
                    <p className="mt-3 fs-4 grey">
                      What are your favourite categories?
                    </p>
                  </div>
                )}
                {stage === 6 && (
                  <div>
                    <img src={six} alt="" width={"70%"} />
                    <p className="mt-3 fs-4 grey">How did you hear about us?</p>
                  </div>
                )}
              </div>
              <div className="mt-4">
                {stage === 1 && (
                  <form onSubmit={(e) => handleSubmitGender(e)}>
                    <div className="mb-3 input-group flex-grow-1">
                      <select
                        value={gender}
                        className="form-control"
                        onChange={handleChangeGender}
                        required
                      >
                        <option value="" disabled>
                          Gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-primary flex-grow-1"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                )}
                {stage === 2 && (
                  <form onSubmit={(e) => handleSubmitBirthday(e)}>
                    <div className="mb-3 input-group flex-grow-1">
                      {/* <ReactDatePicker
                        selected={birthday}
                        onChange={(date) => handleChangeBirthday(date)}
                        className="form-control "
                        placeholderText="Select your date of birth"
                        showMonthDropdown
                        scrollableYearDropdown
                        showYearDropdown
                        required
                      /> */}
                      <DatePicker
                        value={birthday}
                        onChange={(date) => handleChangeBirthday(date)}
                        className="form-control "
                        locale="en-us"
                        required
                      />
                    </div>
                    <div className="text-danger text-start mb-2 ms-1">
                      {birthdayError}
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-primary flex-grow-1"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                )}
                {stage === 3 && (
                  <form onSubmit={(e) => handleSubmitLocation(e)}>
                    <div className="mb-3">
                      <GetLocation
                        onLocationChange={handleLocationChange}
                        location={location}
                      />
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-primary flex-grow-1"
                      >
                        {location === "" ? "Skip" : "Next"}
                      </button>
                    </div>
                  </form>
                )}
                {stage === 4 && (
                  <form onSubmit={(e) => handleSubmitBrands(e)}>
                    <ul className="list-inline d-flex flex-wrap gap-1 mb-3">
                      {famBrands.map((brand, index) => (
                        <li key={index} onClick={() => handleBrandClick(brand)}>
                          <div
                            className={`btn btn-outline-light brand-button btn-sm ${
                              brands.includes(brand.name)
                                ? "bg-light btn-light"
                                : ""
                            }`}
                          >
                            {brand.label}{" "}
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="input-group-md">
                      <Select
                        isMulti
                        className="bg-body"
                        options={allBrands}
                        onChange={handleChangeBrand}
                        placeholder="More brands"
                        menuPortalTarget={menuPortalTarget.current}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "--bs-body-bg",
                            borderColor: "#313235",
                          }),

                          menuList: (base) => ({
                            ...base,
                            "::-webkit-scrollbar-track": {
                              background: "#191a1f",
                            },
                            "::-webkit-scrollbar-thumb": {
                              background: "#ffffff",
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                              background: "#191a1f",
                            },
                          }),
                        }}
                      />
                    </div>
                    <div className="d-grid mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary flex-grow-1"
                      >
                        {brands.length === 0 ? "Skip" : "Next"}
                      </button>
                    </div>
                  </form>
                )}
                {stage === 5 && (
                  <form onSubmit={(e) => handleSubmitCategories(e)}>
                    <div>
                      {Object.keys(primaryCategories).map((categoryKey) => (
                        <div
                          key={categoryKey}
                          className="mt-1 d-flex flex-column align-items-start"
                        >
                          <li
                            className="fs-6 text-mute my-1"
                            style={{
                              borderRadius: "20px",
                              listStyleType: "none",
                            }}
                            onClick={() =>
                              toggleCategoryVisibility(categoryKey)
                            }
                          >
                            <div
                              className={`btn btn-outline-light brand-button btn-sm ${
                                visibleCategories[categoryKey] === true
                                  ? "bg-light btn-light"
                                  : ""
                              }`}
                            >
                              <b>{categoryKey}</b>
                            </div>
                          </li>
                          {visibleCategories[categoryKey] && (
                            <ul className="list-inline mb-0 d-flex flex-wrap gap-1">
                              {primaryCategories[categoryKey].map(
                                (item, index) => (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      handleCategoryClick(
                                        categoryKey,
                                        item.name
                                      )
                                    }
                                  >
                                    <div
                                      className={`btn btn-outline-light brand-button btn-sm ${
                                        categories.some((element) =>
                                          areObjectsEqual(
                                            element,
                                            categoryKey,
                                            item.name
                                          )
                                        )
                                          ? "bg-light btn-light"
                                          : ""
                                      }`}
                                    >
                                      {item.displayName}
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="d-grid mt-2">
                      <button
                        type="submit"
                        className="btn btn-primary flex-grow-1"
                      >
                        {categories.length === 0 ? "Skip" : "Next"}
                      </button>
                    </div>
                  </form>
                )}
                {stage === 6 && (
                  <form onSubmit={(e) => handleSubmission(e)}>
                    <div
                      id="options"
                      className="d-flex flex-wrap justify-content-center"
                    >
                      {options.map((option) => (
                        <React.Fragment key={option.id}>
                          <input
                            type="radio"
                            id={option.id}
                            name="selectedOption"
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={() => handleOptionClick(option.value)}
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor={option.id}
                            className={`option-button btn ${
                              selectedOption === option.value
                                ? "selected btn-light"
                                : "btn-outline-light"
                            }`}
                            onClick={() => handleOptionClick(option.value)}
                          >
                            {option.label}
                          </label>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="d-grid mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary flex-grow-1"
                      >
                        Finish Registration
                      </button>
                    </div>
                  </form>
                )}
              </div>
              <div className="d-grid mt-2">
                <button
                  disabled={stage === 1}
                  className={`btn btn-primary-soft flex-grow-1 
                    `}
                  // ${stage === 1 || stage === 6 ? "d-none" : "d-block"}
                  onClick={() => {
                    if (stage > 1) setStage(stage - 1);
                    setBrands([]);
                  }}
                >
                  Previous
                </button>
              </div>
              <div className="mt-2">
                <TermsAndConditions />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
