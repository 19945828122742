import { doc, collection, getDocs, addDoc, updateDoc, increment, serverTimestamp, query, where, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../FirebaseConfig";


const usePoints = () => {

  // Function to add points to the user and log the points in the pointsLog subcollection
  const addPoints = async ({ userId, type, pointsAwarded, fields }) => {
    try {
      const userRef = doc(db, "users", userId);
      const pointsLogRef = collection(userRef, "pointsLog");

      // Build the query with dynamic fields
      const conditions = [where("type", "==", type)];

      if (fields && typeof fields === "object") {
        Object.keys(fields).forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            conditions.push(where(key, "==", fields[key]));
          }
        });
      }

      // Query for existing points log entries
      const pointsLogQuery = query(pointsLogRef, ...conditions);
      const pointsLogSnapshot = await getDocs(pointsLogQuery);

      // If a matching document already exists, do not proceed
      if (!pointsLogSnapshot.empty) {
        console.log("Points log entry already exists. No points added.");
        return; // Exit if log entry is found
      }

      // Increment the user's points if no log entry exists
      await updateDoc(userRef, {
        points: increment(pointsAwarded),
      });

      // Log the points in the pointsLog subcollection
      await setDoc(doc(pointsLogRef), {
        type: type,
        pointsAwarded: pointsAwarded,
        timestamp: serverTimestamp(),
        ...fields, // Spread dynamic fields
      });

      console.log("Points added and logged successfully!");

    } catch (error) {
      console.error("Error adding points or logging them:", error);
    }
  };

  // Function to remove points from the user and log the deduction in the pointsLog subcollection
  const removePoints = async ({ userId, type, pointsRemoved, fields }) => {
    try {
      const userRef = doc(db, "users", userId);

      // Decrement the user's points
      await updateDoc(userRef, {
        points: increment(-pointsRemoved), // Decrement points
      });

      // Search for the matching document in pointsLog using dynamic conditions
      const pointsLogRef = collection(userRef, "pointsLog");

      // Build the query with dynamic fields
      const conditions = [where("type", "==", type)];

      if (fields && typeof fields === "object") {
        Object.keys(fields).forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            conditions.push(where(key, "==", fields[key]));
          }
        });
      }

      // Run the query to find matching document(s)
      const pointsLogQuery = query(pointsLogRef, ...conditions);
      const pointsLogSnapshot = await getDocs(pointsLogQuery);

      if (!pointsLogSnapshot.empty) {
        // Delete the first matching document (assuming one log per action)
        await deleteDoc(pointsLogSnapshot.docs[0].ref);

        console.log("Points log entry removed successfully!");
      } else {
        console.warn("No matching points log entry found.");
      }

      // Optionally log the removal action as well
      await addDoc(pointsLogRef, {
        type: `remove_${type}`,
        pointsAwarded: -pointsRemoved, // Negative value to show points were removed
        timestamp: serverTimestamp(),
        ...fields, // Spread dynamic fields here as well
      });

      console.log("Points removed and removal logged successfully!");

    } catch (error) {
      console.error("Error removing points or logging them:", error);
    }
  };

  // Function to fetch points history (optionally filtered by type)
  const getPointsLog = async ({ userId, actionType = null }) => {
    try {
      const pointsLogRef = collection(db, "users", userId, "pointsLog");
      
      let pointsQuery;
      
      if (actionType) {
        pointsQuery = query(pointsLogRef, where("type", "==", actionType));
      } else {
        pointsQuery = query(pointsLogRef);
      }

      const pointsSnapshot = await getDocs(pointsQuery);
      const pointsData = pointsSnapshot.docs.map(doc => doc.data());

      return pointsData;
      
    } catch (error) {
      console.error("Error fetching points log:", error);
      return [];
    }
  };

  return { addPoints, removePoints, getPointsLog };
};

export default usePoints;
