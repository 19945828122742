// import Masonry from "react-responsive-masonry";
// import React, { useState } from "react";

// import { useParams } from "react-router-dom";
// import useFetch from "../hooks/useFetch";
// import ProductCard3 from "../Products/ProductCard3";

// function BrandPosts() {
//   const { brandName } = useParams();
//   const [columnCount, setColumnCount] = useState(getColumnCount());
//   const { data, isLoading } = useFetch(
//     process.env.REACT_APP_API + `/api/brandFeed/${brandName}`
//   );

//   function getColumnCount() {
//     const screenWidth = window.innerWidth;
//     let count = 2;
//     if (screenWidth >= 440) {
//       count = 3;
//     }
//     if (screenWidth >= 768) {
//       count = 4;
//     }
//     if (screenWidth >= 900) {
//       count = 4;
//     }
//     return count;
//   }
//   return (
//     <>
//       <Masonry columnsCount={columnCount} className="px-1" gutter="10px">
//         {data?.map((post, index) => (
//           <>
//             <ProductCard3 key={post.id} product={post} />
//           </>
//         ))}
//       </Masonry>
//     </>
//   );
// }

// export default BrandPosts;
import React, { useState, useEffect } from "react";
import Masonry from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../FirebaseConfig";  // Import Firestore instance
import ProductCard3 from "../Products/ProductCard3";

function BrandPosts() {
  const { brandName } = useParams();
  const [columnCount, setColumnCount] = useState(getColumnCount());
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const q = query(
          collection(db, "posts"),
          where("supplier", "==", brandName),
          orderBy("dateCreated", "desc") // Sorting by descending order
        );
        const querySnapshot = await getDocs(q);
        const postsArray = [];
        querySnapshot.forEach((doc) => {
          postsArray.push({ id: doc.id, ...doc.data() });
        });
        setData(postsArray);
      } catch (error) {
        console.error("Error fetching posts: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [brandName]);
  console.log('Data fetched by posts : ',data);
  function getColumnCount() {
    const screenWidth = window.innerWidth;
    let count = 2;
    if (screenWidth >= 440) {
      count = 3;
    }
    if (screenWidth >= 768) {
      count = 4;
    }
    if (screenWidth >= 900) {
      count = 4;
    }
    return count;
  }

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Masonry columnsCount={columnCount} className="px-1" gutter="10px">
          {data?.map((post) => (
            <ProductCard3 key={post.id} product={post?.products[0]} />
          ))}
        </Masonry>
      )}
    </>
  );
}

export default BrandPosts;
