import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import BrandsMenu2 from "./BrandsMenuDropdown2";
import Accordion from "react-bootstrap/Accordion";
import "./style.css";

function HeaderMenu2({
  handleOutsideClick,
  activeAccordion,
  setActiveAccordion,
}) {
  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);

  useEffect(() => {
    const getMenu = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );
      setWomenCategories(categoriesData[1]);
      setMenCategories(categoriesData[0]);
    };

    getMenu();
  }, []);
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index); // Toggle the clicked accordion
  };
  const renderDropdownItems = (categories, gender, count) => {
    return Object.keys(categories)
      ?.sort()
      ?.map((categoryName, index) => (
        <Accordion
          key={categoryName}
          flush
          activeKey={
            activeAccordion === count + index ? `${count + index}` : null
          }
        >
          <Accordion.Item
            className="bg-transparent"
            eventKey={`${count + index}`}
          >
            <Accordion.Header onClick={() => toggleAccordion(count + index)}>
              {categoryName}
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <ul
                className="list-unstyled fw-lighter"
                style={{ marginLeft: "8px" }}
              >
                {categories[categoryName]
                  .filter(
                    (subCategory) =>
                      subCategory.name !== "None" &&
                      subCategory.displayName !== "None"
                  )
                  .map((subCategory, index) => (
                    <li key={index} className="mb-2">
                      <Link
                        className="dropdown-item"
                        to={`/AllProducts/${gender}/${categoryName}/${subCategory.name}`}
                        onClick={handleOutsideClick}
                      >
                        <span
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarCollapse"
                          onClick={handleOutsideClick}
                        >
                          {subCategory.displayName}
                        </span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ));
  };

  return (
    <div class="row">
      <div class="row col-8 text-start px-1">
        {/* MENS */}
        <div class="col ">
          <h6 className="text-start fs-5 fw-normal px-3">Men's</h6>
          <ul
            className="p-0 m-3 "
            id="collapseExample"
            style={{ listStyleType: "none" }}
          >
            {renderDropdownItems(menCategories, "Men", 10)}
          </ul>
        </div>
        {/* WOMENS */}
        <div class="col  ">
          <h6 className="text-start fs-5 fw-normal px-3">Women's</h6>
          <ul
            className="p-0 m-3 "
            id="collapseExample"
            style={{ listStyleType: "none" }}
          >
            {renderDropdownItems(womenCategories, "Women", 20)}
          </ul>
        </div>
        {/* BRANDS */}
        <div
          class="col  "
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          onClick={handleOutsideClick}
        >
          <h6 className="text-start fs-5 fw-normal px-3">Brands</h6>
          <ul className="p-0  m-3 ">
            <BrandsMenu2 />
          </ul>
        </div>
      </div>
      <div
        className="d-flex col px-2 justify-content-end"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        onClick={handleOutsideClick}
      >
        <ul className="text-start px-5" style={{ listStyleType: "none" }}>
          <li>
            <Link
              to="/contactUs"
              className="bg-transparent text-body border-0 "
            >
              <h6 className="fs-5 fw-normal mb-3">Contact us</h6>
            </Link>
          </li>
          <li>
            <Link
              to="/staticPages/privacyPolicy"
              className="bg-transparent text-body border-0 "
            >
              <h6 className="fs-5 fw-normal mb-3">Privacy policy</h6>
            </Link>
          </li>
          <li>
            <Link
              to="/staticPages/termsAndConditions"
              className="bg-transparent text-body border-0 "
            >
              <h6 className="fs-5 fw-normal mb-3">Terms of service</h6>
            </Link>
          </li>
        </ul>
      </div>
    </div>

    // <ul
    //   className="navbar-nav navbar-nav-scroll ms-auto"
    //   style={{ paddingRight: "2%" }}
    // >
    //   <li className="nav-item dropdown">
    // <Link
    //   className="nav-link dropdown-toggle"
    //   href="#"
    //   role="button"
    //   data-bs-toggle="dropdown"
    //   aria-expanded="false"
    // >
    //   Brands
    // </Link>
    // <ul className="dropdown-menu">
    //   <BrandsMenu />
    //     </ul>
    //   </li>

    //   {/* Women's Categories Dropdown */}
    //   <li className="nav-item dropdown">
    //     <Link
    //       className="nav-link dropdown-toggle"
    //       href="#"
    //       role="button"
    //       data-bs-toggle="dropdown"
    //       aria-expanded="false"
    //     >
    //       Women
    //     </Link>
    //     <ul className="dropdown-menu">
    //       {renderDropdownItems(womenCategories, "Women")}
    //     </ul>
    //   </li>

    //   {/* Men's Categories Dropdown */}
    //   <li className="nav-item dropdown">
    //     <Link
    //       className="nav-link dropdown-toggle"
    //       href="#"
    //       role="button"
    //       data-bs-toggle="dropdown"
    //       aria-expanded="false"
    //     >
    //       Men
    //     </Link>
    //     <ul className="dropdown-menu">
    //       {renderDropdownItems(menCategories, "Men")}
    //     </ul>
    //   </li>
    // </ul>
  );
}

export default HeaderMenu2;
