import React from "react";
import { Link } from "react-router-dom";
import { getBaseURL } from "../lib/getBaseURL";
import useModalStore from "../StatesStore/ModalStore";
import { dataFavourite, titleFavourite } from "../Content/ModalContent";
import { UserAuth } from "../Context";

const ShareMenu = ({ product, componentName }) => {
  const baseURL = getBaseURL();
  const { user } = UserAuth();
  const { openModal } = useModalStore();

  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", titleFavourite, dataFavourite); // Open modal here
    }
  };
  // Function to handle copying the link to clipboard
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${baseURL}/#/ProductDetails/${product.id}`
      );
    } catch (error) {
      console.error("Failed to copy link: ", error);
    }
  };
  const handleFacebookShare = (quote, url) => {
    // Open Facebook with the share URL and title
    const shareUrl = encodeURIComponent(url);
    const shareQuote = encodeURIComponent(quote);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://lookflock.com/#/ProductDetails/${shareUrl}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = (title, url) => {
    // Open WhatsApp with the share URL and title
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${title}: https://lookflock.com/#/ProductDetails/${url}`
      )}`
    );
  };
  const handleEmailShare = (title, shareUrl) => {
    // Open default email client with the share URL and title
    window.location.href = `mailto:?subject=${encodeURIComponent(
      title
    )}&body=${encodeURIComponent(shareUrl)}`;
  };

  const handleInstagramShare = (title, url) => {
    window.open(
      `https://www.instagram.com/direct/new/?text=${encodeURIComponent(url)}`
    );
  };

  const socialMediaOptions = [
    {
      name: "facebook",
      label: " Share via Facebook",
      icon: (
        <i
          className="bi bi-facebook"
          style={{ fontSize: "18px", color: "#0057DA" }}
        ></i>
      ),
      handler: (url, quote) => handleFacebookShare(quote, url),
    },
    {
      name: "whatsapp",
      label: " Share via Whatsapp",
      icon: (
        <i
          className="bi bi-whatsapp"
          style={{ fontSize: "18px", color: "#00CF08" }}
        ></i>
      ),
      handler: (title, url) => handleWhatsappShare(title, url),
    },
    {
      name: "email",
      label: " Share via Email",
      icon: (
        <i
          className="bi bi-envelope fa-fw"
          style={{ fontSize: "18px", color: "#EE3900" }}
        ></i>
      ),
      handler: (title, url) => handleEmailShare(title, url),
    },
    {
      name: "instagram",
      label: " Share via Instagram",
      icon: (
        <i
          className="bi bi-instagram fa-fw"
          style={{ fontSize: "18px", color: "#E1306C" }}
        ></i>
      ),
      handler: (title, url) =>handleInstagramShare(title, url),
    },
  ];

  return (
    <>
      {!user && (
        <Link
          className={`nav-link ${
            componentName === "productDescriptions" ? "" : "icon-sm"
          } gap-2 p-0 m-0 nav-item `}
          onClick={handleClick}
        >
          <i className="bi bi-share fs-6"> </i>
          {/* {componentName === "productDescriptions" && "Share"} */}
        </Link>
      )}
      {user && (
        <Link
          className={`nav-link ${
            componentName === "productDescriptions" ? "" : "icon-sm"
          } gap-2 p-0 m-0 nav-item `}
          id="eventActionShare"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-share fs-6"> </i>
          {/* {componentName === "productDescriptions" && "Share"} */}
        </Link>
      )}{" "}
      <span class="">
        {/* <!-- Dropdown menu --> */}
        <ul
          className="dropdown-menu dropdown-menu-end mt-0"
          aria-labelledby="eventActionShare"
        >
          <div style={{ display: "inline-flex", gap: "10px" }}>
          {socialMediaOptions.map((option, index) => (
            <li key={index}>
              <Link
                className="dropdown-item"
                to=""
                onClick={() => option.handler(product.name, product.id)}
              >
                {option.icon}
                <span style={{ fontSize: "17px" }}></span>
            
              </Link>
              <hr className="dropdown-divider m-0" />
            </li>
          ))}
          </div>
          {/* <li>
            <Link
            className="dropdown-item"
            to=""
            data-bs-toggle="modal"
            data-bs-target="#modalCreateFeed"
            >
            <i className="bi bi-person"></i> Share to my profile
            </Link>
            </li> */}
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-sm btn-primary-soft "
              onClick={() => handleCopyLink()}
            >
              <i class="bi bi-link-45deg"></i> Copy Link
            </button>
          </div>
        </ul>
      </span>
    </>
  );
};

export default ShareMenu;
