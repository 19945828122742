// UpdatePFP.js
import React, { useCallback, useState } from "react";
import ProfilePicture from "./ProfilePicture";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
const UpdatePFP = ({
  Uid,
  userData,
  avatarSize,
  className,
  onProfilePictureUpdate,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showModal, setShowModal] = useState(false); // Control modal visibility

  const { uid } = useParams();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const getCroppedImg = async (imageSrc, crop) => {
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.setAttribute("crossOrigin", "anonymous"); // Avoid CORS issues
        image.src = url;
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height, // source crop rectangle
      0,
      0,
      crop.width,
      crop.height // destination rectangle
    );

    return new Promise((resolve) => {
      resolve(canvas.toDataURL("image/jpeg"));
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
      setShowModal(true); // Show modal when an image is selected
    };
  };
  function base64ToFile(base64String, fileName, mimeType) {
    // Convert the base64 string to a byte array
    let byteString = atob(base64String.split(",")[1]);

    // Create an array buffer and a view
    let arrayBuffer = new ArrayBuffer(byteString.length);
    let uint8Array = new Uint8Array(arrayBuffer);

    // Populate the view with the byte array
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the array buffer and then convert it into a File object
    let file = new File([uint8Array], fileName, { type: mimeType });
    return file;
  }
  const handleSaveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      // console.log(croppedImage);
      setShowModal(false); // Close the modal after saving
      const file = base64ToFile(croppedImage, "image.jpg", "image/jpeg");
      console.log(file);
      const storage = getStorage();
      const storageRef = ref(storage, `profilePictures/${Uid}`);

      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);

        const userDocRef = doc(db, "users", Uid);
        await updateDoc(userDocRef, {
          photo: downloadURL,
        });

        console.log("Profile picture updated successfully:", downloadURL);
        onProfilePictureUpdate(); // Notify the parent to trigger re-render
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageSrc]);

  return uid === Uid ? (
    <>
      <ProfilePicture
        userData={userData}
        avatarSize={avatarSize}
        className={className}
        location="header"
        onPictureClick={triggerFileInput}
        isHovered={isHovered} // Pass down isHovered state
        onMouseEnter={handleMouseEnter} // Pass down handleMouseEnter
        onMouseLeave={handleMouseLeave} // Pass down handleMouseLeave
      />
      <span
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
          fontSize: "12px",
          fontWeight: "bold",
          borderRadius: "8px",
          pointerEvents: "none", // Ensure clicks go to the input
          opacity: isHovered ? 1 : 0, // Change opacity based on isHovered
          transition: "opacity 0.3s", // Smooth transition
        }}
        className="change-picture-text"
      >
        Change Picture
      </span>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
      {/* Modal for cropping */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop Your Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="crop-container position-relative"
            style={{ height: 300, width: "100%", backgroundColor: "#f8f9fa" }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              cropShape="square"
            />
          </div>
          <div className="mt-3 d-flex align-items-center">
            <span className="mr-2">Zoom</span>
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e) => setZoom(e.target.value)}
              className="custom-range"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSaveCroppedImage()}>
            Save Cropped Image
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <ProfilePicture
      userData={userData}
      avatarSize={avatarSize}
      className={className}
    />
  );
};

export default UpdatePFP;
