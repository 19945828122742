import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  startAfter,
  limit,
  orderBy,
  doc,
  getDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import useNotification from "../hooks/useNotification";
import usePointsStore from "../StatesStore/PointsTable";
import usePoints from "../hooks/user/usePoints";
import useFeedNotification from "../hooks/useFeedNotification";
import AddComment from "./AddComment";
import { Accordion } from "react-bootstrap";

function Comment({ comment, user, post, handleDeleteComment }) {
  console.log("newComments comment", comment);
  const [replyCount, setReplyCount] = useState(comment?.replies?.length || 0);
  return (
    <li className="comment-item my-2">
      <div className="d-flex">
        {/* Avatar */}
        <div className="avatar avatar-xs ">
          <ProfilePicture userData={comment} className="avatar-img rounded-2" />
        </div>
        {/* Comment by */}
        <div className="ms-2 w-100">
          <div className="bg-light rounded-start-top-0 ps-4 pe-3 py-2 rounded">
            <div className="d-flex justify-content-between mb-1 w-100">
              <h6 className="mb-0">
                {" "}
                <Link
                  to={
                    comment ? `/userProfile/${comment.userId}` : "/userProfile"
                  }
                >
                  {" "}
                  {comment?.userName
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}{" "}
                </Link>{" "}
              </h6>
              <span className="mb-0">
                {formatTimestampForPost(comment?.timestamp)}
              </span>
            </div>
            <p className="mb-0 h6 fw-light text-body">{comment?.content}</p>
          </div>

          <>
            <div className="mx-1 pe-2 w-100 d-flex justify-content-between">
              <Accordion flush className="w-100">
                <style jsx>
                  {`
                    .accordion-button::after {
                      display: none !important; /* Hides the default arrow */
                    }
                  `}
                </style>
                <Accordion.Item className="bg-transparent" eventKey={0}>
                  <Accordion.Header className="d-inline-block">
                    <Link to="" className="fw-light h6 m-0 p-0">
                      Reply ({replyCount})
                    </Link>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 m-0 mt-1">
                    {comment?.replies.length !== 0 &&
                      comment?.replies?.map((com, index) => {
                        return (
                          <Comment
                            comment={com}
                            key={index}
                            post={post}
                            user={user}
                            handleDeleteComment={handleDeleteComment}
                          />
                        );
                      })}
                    <div className="ms-2">
                      <AddComment
                        post={comment}
                        user={user}
                        setCommentCount={setReplyCount}
                        type={"reply"}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {(post?.userId === user?.uid ||
                comment?.userId === user?.uid) && (
                <Link
                  to=""
                  className=" fw-light h6 m-0 pt-1 position-absolute"
                  style={{ right: "1%" }}
                  onClick={() =>
                    handleDeleteComment(comment?.reference, comment.id)
                  }
                >
                  Delete
                </Link>
              )}
            </div>
          </>
        </div>
      </div>
    </li>
  );
}

export default function ShowComment({
  post,
  commentCount,
  setCommentCount,
  user,
}) {
  const [comments, setComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(3);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const { deleteNotification } = useNotification();
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const { removePoints } = usePoints();
  //const [countLeft, setCountLeft] = useState(0);
  const { deleteFeedNotification } = useFeedNotification();

  const commentsCollectionRef = collection(db, "posts", post?.id, "comments");
  const fetchComments = () => {
    let q;
    if (lastVisible) {
      q = query(
        commentsCollectionRef,
        orderBy("timestamp", "asc"),
        startAfter(lastVisible),
        limit(3)
      );
    } else {
      q = query(commentsCollectionRef, orderBy("timestamp", "asc"), limit(3));
    }

    //recursion applies here for replies
    const fetchReplies = async (collectionRef, docID) => {
      const replyRef = collection(collectionRef, docID, "replies");
      const q = query(replyRef, orderBy("timestamp", "asc"));
      const replySnap = await getDocs(q);
      if (replySnap.size === 0) {
        return [];
      } else {
        const replies = await Promise.all(
          replySnap.docs.map(async (reply) => {
            let returnObj = {
              id: reply?.id,
              ...reply?.data(),
              userName: "", // Initialize userName to empty string
              photo: "",
              reference: replyRef,
              replies: await fetchReplies(replyRef, reply?.id),
            };
            const userDocRef = doc(db, "users", reply?.data().userId);
            const userSnapshot = await getDoc(userDocRef);
            if (userSnapshot.exists()) {
              returnObj.userName = userSnapshot.data().userName; // Update userName
              returnObj.photo = userSnapshot.data().photo;
            } else {
              // Handle the case where the user document doesn't exist
              console.error(
                "User document not found for userId:",
                returnObj.userId
              );
            }
            return returnObj;
          })
        );
        return replies;
      }
    };
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const newComments = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          return {
            id: doc.id,
            ...doc.data(),
            userName: "", // Initialize userName to empty string
            photo: "",
            reference: commentsCollectionRef,
            replies: await fetchReplies(commentsCollectionRef, doc?.id),
          };
        })
      );
      console.log("newComments", newComments);
      Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);
          if (userSnapshot.exists) {
            comment.userName = userSnapshot.data().userName; // Update userName
            comment.photo = userSnapshot.data().photo;
          } else {
            // Handle the case where the user document doesn't exist
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }
        })
      )
        .then(() => {
          setComments((prevComments) => {
            if (lastVisible) {
              // If there is a lastVisible, it means this is a load more operation
              return [...prevComments, ...newComments];
            } else {
              // If there is no lastVisible, it's a fresh load, so just set the new comments
              return newComments;
            }
          });
        })
        .catch((error) => {
          // Handle any errors during user data fetching
          console.error("Error fetching user data:", error);
        });
      // Ensure loading is set to false after data is fetched

      // Update lastVisible only if new comments are fetched

      if (newComments.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    fetchComments();
  }, [post.id]);

  const loadMoreComments = () => {
    setLoading(true);
    fetchComments();
    setVisibleComments((prev) => prev + 3);
  };
  const handleDeleteComment = async (reference, commentUID) => {
    console.log(commentUID);
    const commentDoc = doc(reference, commentUID);
    await deleteDoc(commentDoc);
    setCommentCount((prev) => prev - 1);
    if (post.userId !== user?.uid) {
      await deleteNotification({
        userId: post?.userId,
        id: user?.uid,
        type: "postComment",
        by: "user",
        fields: {
          postId: post?.id,
        },
      });
    }

    //deleting points
    const postingPoints = pointsTable.commentingOnUserPost || 0;
    removePoints({
      userId: user?.uid,
      type: "commentingOnUserPost",
      pointsRemoved: postingPoints,
      fields: {
        postId: post?.id,
      },
    });

    deleteFeedNotification({
      ownerId: post.userId,
      id: user?.uid,
      type: "postComment",
      by: "user",
      fields: {
        postId: post?.id,
      },
    });
  };

  return (
    <>
      {/* Comment wrap START */}
      <ul className="comment-item-nested list-unstyled">
        {/* Comment item START */}
        {comments?.map((comment, index) => {
          return (
            <Comment
              comment={comment}
              key={index}
              post={post}
              user={user}
              handleDeleteComment={handleDeleteComment}
            />
          );
        })}

        {/* Comment item END */}
      </ul>
      {commentCount > visibleComments && !loading && (
        <div className="card-footer border-0 pt-0">
          <button
            className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center"
            onClick={loadMoreComments}
            disabled={loading}
          >
            <div className="spinner-dots me-2">
              <span className="spinner-dot"></span>
              <span className="spinner-dot"></span>
              <span className="spinner-dot"></span>
            </div>
            Load more comments
          </button>
        </div>
      )}
      {/* Comment wrap END */}
    </>
  );
}
