import React, { useState, useEffect } from "react";
import {
  updateProfile,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  fetchSignInMethodsForEmail,
  linkWithCredential,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth, googleProvider, facebookProvider } from "../FirebaseConfig";
import WebFont from "webfontloader";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { UserAuth } from "../Context";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import useUserStore from "../StatesStore/UserStore";
import { toastOptions } from "../lib/toastOptions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { generateReferralCode } from "../lib/generateReferralCode";
import { handleInvite } from "../Referral/HandleInvite";
import TermsAndConditions from "./TermsAndConditions";
import { Button } from "react-bootstrap";
import { WelcomeUser } from "../EmailSystem/sendEmails";
import useUrlState from "../StatesStore/CurrentURL";
import usePointsStore from "../StatesStore/PointsTable";
function SignIn() {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("ref");
  const url = useUrlState((state) => state.url);
  const redirectUser = useNavigate();
  const { setUser, createId } = useUserStore(); //for storing user
  const [values, setValues] = useState({
    email: "",
    pass: "",
  });

  const [errorMsg, setErrorMsg] = useState({
    email: "",
    pass: "",
  });
 
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const { user } = UserAuth();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  googleProvider.addScope("email");
  facebookProvider.addScope("email");
  const googleLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/google-logo.svg";
  const facebookLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/facebook-logo.svg";
  // const microsoftLogoPath =
  //   process.env.PUBLIC_URL + "/assets/images/logo/microsoft-logo.svg";
  // const appleLogoBlackPath =
  //   process.env.PUBLIC_URL + "/assets/images/logo/apple-logo-black.svg";
  // const appleLogoWhitePath =
  //   process.env.PUBLIC_URL + "/assets/images/logo/apple-logo-white.svg";
  useEffect(() => {
    if (user) {
      redirectUser("/");
    }
  }, []);

  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe");
    const storedRememberedEmail = localStorage.getItem("rememberedEmail");

    if (storedRememberMe) {
      setRememberMe(JSON.parse(storedRememberMe));
    }

    if (storedRememberMe && storedRememberedEmail) {
      setValues((prevValues) => ({
        ...prevValues,
        email: JSON.parse(storedRememberedEmail),
      }));
    }

    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
  }, []);

  const updateRememberMe = () => {
    if (rememberMe) {
      localStorage.setItem("rememberMe", JSON.stringify(true));
      localStorage.setItem("rememberedEmail", JSON.stringify(values.email));
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("rememberedEmail");
    }
  };

  const isEmailValid = (email) => {
    // Add your email validation logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmission = async () => {
    setErrorMsg({
      email: "",
      pass: "",
    });

    if (!values.email) {
      setErrorMsg((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      return;
    } else if (!isEmailValid(values.email)) {
      setErrorMsg((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return;
    } else if (!values.pass) {
      setErrorMsg((prevErrors) => ({
        ...prevErrors,
        pass: "Password is required",
      }));
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, values.email, values.pass);
      const currentUser = auth.currentUser;
      console.log("currentUser", currentUser);
      if (currentUser && !currentUser.displayName) {
        const displayName = "DefaultDisplayName";
        await updateProfile(currentUser, { displayName });
      }

      updateRememberMe();
      // redirectUser('/');
      redirectUser(url);
    } catch (error) {
      setErrorMsg((prevErrors) => ({
        ...prevErrors,
        // email: "Invalid email or password",
        pass: "Invalid email or password",
      }));
    }
  };

  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("result", result);
      return result;
    } catch (error) {
      throw error;
    }
  };
  const facebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log(result.user);
      return result;
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const pendingCred = FacebookAuthProvider.credentialFromError(error);
        const email = error.customData.email;
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        console.log(signInMethods);
        if (signInMethods.includes("google.com")) {
          try {
            alert(
              "Account with this email " +
                email +
                " already exists. You need to login with gmail first in order to link both accounts"
            );
            const userCredential = await signInWithPopup(auth, googleProvider);
            await linkWithCredential(userCredential.user, pendingCred);
            console.log("Successfully linked existing account with Facebook");
          } catch (linkError) {
            console.error("Error linking credential:", linkError);
          }
        } else if (signInMethods.includes("password")) {
          try {
            const password = prompt(
              "Account with this email " +
                email +
                " already existed. Enter you password in order to link both accounts:"
            );
            const userCredential = await signInWithEmailAndPassword(
              auth,
              email,
              password
            ); // No password needed
            await linkWithCredential(userCredential.user, pendingCred);
            console.log("Successfully linked existing account with Facebook");
          } catch (linkError) {
            console.error("Error linking credential:", linkError);
          }
        } else {
          console.log("Other sign-in methods detected:", signInMethods);
          // Handle other provider linking if needed
        }
      } else {
        console.error("Error during sign-in:", error);
      }
    }
  };
  // const appleSignIn = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, appleProvider);
  //     return result;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const handleGoogleSignIn = async () => {
    try {
      const result = await googleSignIn();
      // Check if the result is defined and has a 'user' property
      if (result && result.user) {
        // Extract user information from the Google sign-in result
        const { user } = result;
        if (user) {
          console.log("user google", user);
          // console.log(user.emailVerified);
          user.emailVerified = true;
          user.email = user.providerData[0].email;
          // console.log("BEFORE CREATING ID");
          createId(user.uid);
          if (!user.displayName) {
            const displayName = "DefaultDisplayName";
            await updateProfile(user, { displayName });
          }

          // Store user information in Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);

          // Check if the user already exists in Firestore
          const docSnap = await getDoc(userRef);
          console.log("here ser google", user);
          //new user
          if (!docSnap.exists()) {
            await WelcomeUser(user.displayName, user.providerData[0]?.email);
            console.log("BEFORE SETING IT TRUE");
            auth.currentUser.emailVerified = true;
            fetch(process.env.REACT_APP_API + "/api/updateEmailVerified", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ uid: user.uid }),
            });
            const referralCode = generateReferralCode();
            const displayNameParts = (
              user.displayName || "DefaultDisplayName"
            ).split(" ");
            //make user dictionary
            const newUser = {
              userName: user.displayName || "DefaultDisplayName",
              email: user.email,
              firstName: displayNameParts[0] || null,
              lastName: displayNameParts.slice(1).join(" ") || null,
              birthday: user.birthday || null,
              phoneNumber: user.phoneNumber || null,
              gender: user.gender || null,
              photo: user.photoURL || null,
              overview: user.overview || null,
              products: user.products || null,
              timestamp: serverTimestamp(),
              location: null,
              userTitle: null,
              requstList: user.requestList || [],
              followingList: user.followingList || [],
              followerList: user.followerList || [],
              friendRequests: user.friendRequests || {},
              post: user.post || {},
              shoppinglist: user.shoppinglist || {},
              terms: false, //adding this to users object in firestore
              heardAboutUs: "",
              referralCode: referralCode,
              isVerified: true,
            };
            handleInvite(inviteCode, user.uid, user.email,pointsTable);
            //save the state of user
            await setUser(newUser);
            //set document in firestore here
            await setDoc(userRef, newUser);
            //user has no gender or birthday
            try {
              toast.success("Sign in with google successfull!", toastOptions);
              //redirect to more questions
              redirectUser("/SignInInput");
            } catch (error) {
              console.log("Error Signing in");
              toast.error("Sign up failed. Please try again.", toastOptions);
              redirect("/SignIn");
            }
          } else {
            console.log("GOING BACK TO OLD URL BYEEE!!");
            if (user.gender === null || user.birthday === null)
              redirectUser("/SignInInput");
            redirectUser(url);
          }
        }
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      if (error.code === "auth/popup-closed-by-user") {
        // alert("Google Sign-In popup closed. Please try again.");
        redirectUser("/SignIn");
      } else if (error.code === "auth/cancelled-popup-request") {
        console.error(error);
        // alert("Google Sign-In request cancelled. Please try again.");
      } else {
        console.error(error);
        // alert("Google Sign-In failed. Please try again.");
      }
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const result = await facebookSignIn();
      console.log(result);
      // Check if the result is defined and has a 'user' property
      if (result && result.user) {
        // Extract user information from the Google sign-in result
        const { user } = result;
        if (user) {
          console.log("user facebook", user);
          createId(user.uid);
          if (!user.displayName) {
            const displayName = "DefaultDisplayName";
            await updateProfile(user, { displayName });
          }

          // Store user information in Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);

          // Check if the user already exists in Firestore
          const docSnap = await getDoc(userRef);
          console.log("here ser facebook", user);
          //new user
          if (!docSnap.exists()) {
            await WelcomeUser(user.displayName, user.providerData[0]?.email);
            fetch(
              "https://us-central1-duplookflock-beada.cloudfunctions.net/api/api/updateEmailVerified",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid: user.uid }),
              }
            );
            auth.currentUser.emailVerified = true;
            const referralCode = generateReferralCode();
            const displayNameParts = (
              user.displayName || "DefaultDisplayName"
            ).split(" ");
            //make user dictionary
            const newUser = {
              userName: user.displayName || "DefaultDisplayName",
              email: user.email,
              firstName: displayNameParts[0] || null,
              lastName: displayNameParts.slice(1).join(" ") || null,
              birthday: user.birthday || null,
              phoneNumber: user.phoneNumber || null,
              gender: user.gender || null,
              photo: user.photoURL || null,
              overview: user.overview || null,
              products: user.products || null,
              timestamp: serverTimestamp(),
              location: null,
              userTitle: null,
              requestList: user.requestList || [],
              followingList: user.followingList || [],
              followerList: user.followerList || [],
              friendRequests: user.friendRequests || {},
              post: user.post || {},
              shoppinglist: user.shoppinglist || {},
              terms: false, //adding this to users object in firestore
              heardAboutUs: "",
              referralCode: referralCode,
              isVerified: true,
            };
            handleInvite(inviteCode, user.uid, user.email,pointsTable);
            //user has no gender or birthday
            //save the state of user
            await setUser(newUser);
            //set document in firestore here
            await setDoc(userRef, newUser);
            //redirect to gender/birthday compoennt
            try {
              toast.success("Sign in with Facebook successfull!", toastOptions);
              //redirect to more questions
              redirectUser("/SignInInput");
            } catch (error) {
              console.log("Error Signing in");
              toast.error("Sign up failed. Please try again.", toastOptions);
              redirect("/SignIn");
            }
          } else {
            redirectUser(url);
          }
        }
      }
    } catch (error) {
      console.error("Error during Facebook sign-in:", error);
      if (error.code === "auth/popup-closed-by-user") {
        // alert("Facebook Sign-In popup closed. Please try again.");
        redirectUser("/login");
      } else if (error.code === "auth/cancelled-popup-request") {
        console.error(error);
        // alert("Facebook Sign-In request cancelled. Please try again.");
      } else {
        console.error(error);
        // alert("Facebook Sign-In failed. Please try again.");
      }
    }
  };

  // const handleAppleSignIn = async () => {
  //   try {
  //     const result = await appleSignIn();
  //     console.log(result);
  //     // Check if the result is defined and has a 'user' property
  //     if (result && result.user) {
  //       // Extract user information from the Google sign-in result
  //       const { user } = result;
  //       if (user) {
  //         console.log("user apple", user);
  //         createId(user.uid);
  //         if (!user.displayName) {
  //           const displayName = "DefaultDisplayName";
  //           await updateProfile(user, { displayName });
  //         }

  //         // Store user information in Firestore
  //         const db = getFirestore();
  //         const userRef = doc(db, "users", user.uid);

  //         // Check if the user already exists in Firestore
  //         const docSnap = await getDoc(userRef);
  //         console.log("here ser facebook", user);
  //         //new user
  //         if (!docSnap.exists()) {
  //           await WelcomeUser(user.displayName, user.providerData[0]?.email);
  //           await fetch(
  //             "https://us-central1-duplookflock-beada.cloudfunctions.net/api/api/updateEmailVerified",
  //             {
  //               method: "POST",
  //               headers: {
  //                 "Content-Type": "application/json",
  //               },
  //               body: JSON.stringify({ uid: user.uid }),
  //             }
  //           );
  //           auth.currentUser.emailVerified = true;
  //           const referralCode = generateReferralCode();
  //           const displayNameParts = (
  //             user.displayName || "DefaultDisplayName"
  //           ).split(" ");
  //           //make user dictionary
  //           const newUser = {
  //             userName: user.displayName || "DefaultDisplayName",
  //             email: user.email,
  //             firstName: displayNameParts[0] || null,
  //             lastName: displayNameParts.slice(1).join(" ") || null,
  //             birthday: user.birthday || null,
  //             phoneNumber: user.phoneNumber || null,
  //             gender: user.gender || null,
  //             photo: user.photoURL || null,
  //             overview: user.overview || null,
  //             products: user.products || null,
  //             timestamp: serverTimestamp(),
  //             location: null,
  //             userTitle: null,
  //             requestList: user.requestList || [],
  //             followingList: user.followingList || [],
  //             followerList: user.followerList || [],
  //             friendRequests: user.friendRequests || {},
  //             post: user.post || {},
  //             shoppinglist: user.shoppinglist || {},
  //             terms: false, //adding this to users object in firestore
  //             heardAboutUs: "",
  //             referralCode: referralCode,
  //             isVerified: true,
  //           };
  //           handleInvite(inviteCode, user.uid, user.email);
  //           //user has no gender or birthday
  //             //save the state of user
  //             setUser(newUser);
  //             //set document in firestore here
  //             await setDoc(userRef, newUser);
  //             try {
  //               //redirect to more questions
  //               redirectUser("/SignInInput");
  //             } catch (error) {
  //               console.log("Error Signing in");
  //               toast.error("Sign up failed. Please try again.", toastOptions);
  //               redirect("/SignIn");
  //             }
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error during Facebook sign-in:", error);
  //     if (error.code === "auth/popup-closed-by-user") {
  //       // alert("Facebook Sign-In popup closed. Please try again.");
  //       redirectUser("/login");
  //     } else if (error.code === "auth/cancelled-popup-request") {
  //       console.error(error);
  //       // alert("Facebook Sign-In request cancelled. Please try again.");
  //     } else {
  //       console.error(error);
  //       // alert("Facebook Sign-In failed. Please try again.");
  //     }
  //   }
  // };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif", "inter"],
      },
    });
  }, []);

  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe");
    const storedRememberedEmail = localStorage.getItem("rememberedEmail");

    if (storedRememberMe) {
      setRememberMe(JSON.parse(storedRememberMe));
    }

    if (storedRememberMe && storedRememberedEmail) {
      setValues((prevValues) => ({
        ...prevValues,
        email: JSON.parse(storedRememberedEmail),
      }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      // If user exists, navigate to the desired location
      // redirectUser("/");
    } else {
      redirectUser("/SignIn");
    }
  }, []);

  return (
    <>
      <div className="container ">
        <div className="row justify-content-center align-items-center vh-100 py-2 ">
          <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
            <div className="card card-body text-center rounded-3 p-4 ">
              <Link className="text-body text-center fs-icon " to="/">
                <div
                  style={{
                    fontFamily: "Nunito Sans",
                  }}
                >
                  <i>lookflock&nbsp;</i>
                </div>
              </Link>
              <p className="mb-0">
                Don't have an account?
                <Link to="/SignUp">
                  {" "}
                  <u className="text-body">Click here to Sign Up</u>
                </Link>
              </p>
              <div className="mt-4">
                <div class="mb-3 input-group">
                  <div className="input-group">
                    <input
                      name="newEmail"
                      id="newEmail"
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={values.email}
                      onChange={(event) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          email: event.target.value,
                        }));
                        setErrorMsg((prevErrors) => ({
                          ...prevErrors,
                          email: "",
                        }));
                      }}
                    />
                  </div>
                  <span
                    style={{ color: "red", display: "flex", marginTop: "3px" }}
                  >
                    {errorMsg.email}
                  </span>
                </div>
                <div className="mb-0 ">
                  <div className="input-group">
                    <input
                      name="newPassword"
                      id="newPassword"
                      type={showPassword ? "text" : "password"}
                      class="form-control"
                      placeholder="Password"
                      value={values.pass}
                      onChange={(event) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          pass: event.target.value,
                        }));
                        setErrorMsg((prevErrors) => ({
                          ...prevErrors,
                          pass: "",
                        }));
                      }}
                    />
                    <span
                      className="input-group-text p-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`fakepasswordicon fa-solid fa-eye${
                          showPassword ? "-slash" : ""
                        } cursor-pointer p-2 w-40px`}
                      ></i>
                    </span>
                  </div>
                  <span
                    style={{ color: "red", display: "flex", marginTop: "3px" }}
                  >
                    {errorMsg.pass}
                  </span>
                </div>

                <div className="mb-3 mt-2 d-sm-flex justify-content-between">
                  <div
                    className="form-check d-flex align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <input
                      style={{ marginRight: "5px" }}
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={() => {
                        setRememberMe(!rememberMe);
                        updateRememberMe();
                      }}
                    />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember Me
                    </label>
                  </div>
                  <Link to="/Reset">
                    <u className="text-body">Forgot password?</u>
                  </Link>
                </div>
                <div className="d-grid">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleSubmission}
                  >
                    Sign In
                  </Button>

                  <div className="container d-flex flex-column justify-content-center align-items-center mt-4">
                    <div className="w-100 d-flex align-items-center">
                      <hr className="flex-grow-1" />
                      <span className="px-2"> Or continue with </span>
                      <hr className="flex-grow-1" />
                    </div>

                    <div className="d-flex justify-content-center my-1">
                      <button className="btn mx-4" onClick={handleGoogleSignIn}>
                        <img
                          src={googleLogoPath}
                          className="mx-1"
                          alt="Google"
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </button>
                      <button
                        className="btn mx-4"
                        onClick={handleFacebookSignIn}
                      >
                        <img
                          src={facebookLogoPath}
                          className="mx-1"
                          alt="Facebook"
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </button>
                      {/* <button className="btn">
                        <img src={microsoftLogoPath} className="mx-1" alt="Microsoft" style={{ width: "40px", height: "40px", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                      </button>
                      <button className="btn" onClick={handleAppleSignIn}>
                        <img src={document.documentElement.getAttribute("data-bs-theme") === "light" ? appleLogoBlackPath : appleLogoWhitePath} className="mx-1" alt="Apple" style={{ width: "40px", height: "40px", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                      </button> */}
                    </div>
                  </div>
                  <TermsAndConditions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;