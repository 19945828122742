import { collection, doc, setDoc, getDocs, query, where, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseConfig"; // Assume FirebaseConfig contains the correct setup for Firestore

function usePostAction() {

  // Add a post action notification
  const addPostActionNotification = async ({ ownerId, postId, actionType, actionUserId, fields }) => {
    try {
      const postActionRef = collection(db, "testPosts");

      // Query to check if a document with the same postId, actionType, actionUserId, ownerId, and fields already exists
      const notificationQueryConditions = [
        where("postId", "==", postId),
        where("actionType", "==", actionType),
        where("actionUserId", "==", actionUserId),
        where("ownerId", "==", ownerId)
      ];

      // Dynamically add the fields to the query conditions
      if (fields && typeof fields === "object") {
        Object.keys(fields).forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            notificationQueryConditions.push(where(key, "==", fields[key]));
          }
        });
      }

      const notificationQuery = query(postActionRef, ...notificationQueryConditions);
      const notificationSnapshot = await getDocs(notificationQuery);

      if (!notificationSnapshot.empty) {
        // If a matching document exists, update its timestamp
        const docRef = notificationSnapshot.docs[0].ref;
        await updateDoc(docRef, {
          timestamp: serverTimestamp(), // Only update the timestamp
        });
        console.log("Post action notification updated with new timestamp.");
      } else {
        // If no matching document exists, create a new one
        const postActionData = {
          timestamp: serverTimestamp(),
          postId,          // Store the post ID
          actionType,      // Store the action type
          actionUserId,    // Store the action user ID
          ownerId,         // Store the owner ID
          ...fields,       // Spread the remaining fields
        };
        await setDoc(doc(postActionRef), postActionData); // Create a new document
        console.log("Post action notification added:", postActionData);
      }
    } catch (error) {
      console.error("Error adding post action notification: ", error);
    }
  };

  // Delete a post action notification
  const deletePostActionNotification = async ({ ownerId, postId, actionUserId, fields }) => {
    try {
      const postActionRef = collection(db, "testPosts");

      // Query to match the postId, actionUserId, ownerId, and dynamic fields
      const notificationQueryConditions = [
        where("postId", "==", postId),
        where("actionUserId", "==", actionUserId),
        where("ownerId", "==", ownerId)
      ];

      // Dynamically add the fields to the query conditions for deletion
      if (fields && typeof fields === "object") {
        Object.keys(fields).forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            notificationQueryConditions.push(where(key, "==", fields[key]));
          }
        });
      }

      const notificationQuery = query(postActionRef, ...notificationQueryConditions);
      const notificationSnapshot = await getDocs(notificationQuery);

      notificationSnapshot.forEach(async (docSnapshot) => {
        // Check if the document matches the postId, actionUserId, ownerId, and all fields
        const data = docSnapshot.data();
        let isMatch = data.postId === postId && data.actionUserId === actionUserId && data.ownerId === ownerId;

        // Check if all dynamic fields match
        if (fields && typeof fields === "object") {
          Object.keys(fields).forEach((key) => {
            if (fields[key] !== undefined && data[key] !== fields[key]) {
              isMatch = false; // Set to false if any field doesn't match
            }
          });
        }

        if (isMatch) {
          await deleteDoc(docSnapshot.ref); // Delete the document
          console.log(`Post action for user ${actionUserId}, owner ${ownerId}, and fields removed.`);
        }
      });
    } catch (error) {
      console.error("Error deleting post action notification: ", error);
    }
  };

  return { addPostActionNotification, deletePostActionNotification };
}

export default usePostAction;
