import React from "react";
import { Link } from "react-router-dom";
import { useFriendActions } from "../hooks/user/useFriendActions";
import ProfilePicture from "../UserProfile/ProfilePicture";

const UserProfileCard = ({ searchUser, user, componentName }) => {
  const {
    requestList,
    pendingList,
    activeList,
    handleFriendAction,
  } = useFriendActions(searchUser, user);

  if (!searchUser) {
    return <div className="loading-indicator">Loading...</div>;
  }
console.log("The search user is",searchUser);
  return (
    <div
      className={`${
        "Users"
          ? "col-6 col-sm-6 col-md-4 col-lg-3"
          : "col-6 col-sm-4 col-md-3 col-lg-2"
      }`}
    >
      <div className="card shadow-none text-center">
        <div className="card-body p-2 pb-0">
          <div className="avatar avatar-xl">
            <Link to={`/userProfile/${searchUser.objectID}`}>
               <ProfilePicture userData={searchUser?.objectUser} className='avatar-img rounded' />
            </Link>
          </div>
          <h6 className="card-title mb-1 mt-3">
            <Link to={`/userProfile/${searchUser?.objectID}`}>
              {searchUser?.displayName}
            </Link>
          </h6>
        </div>
        {searchUser?.objectID !== user?.uid ? (
          <div className="card-footer p-2 border-0">
            <button
              className="btn btn-sm btn-primary-soft w-100"
              onClick={() => {
                handleFriendAction(searchUser?.objectID,user );
              }}
            >
              {activeList && activeList.includes(searchUser?.objectID)
                ? " Unfriend"
                : requestList && requestList.includes(searchUser?.objectID)
                ? " Cancel Request"
                :  pendingList && pendingList.includes(searchUser?.objectID)
                ? " Respond"
                : " Add Friend"}
            </button>
          </div>
        ) : (
          <div className="card-footer p-2 border-0">
            <Link
              to={`/userProfile/${searchUser?.objectID}`}
              className="btn btn-sm btn-primary-soft w-100"
            >
              View
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfileCard;
