import React from "react";
import BrandsHomeSideNav from "./BrandsPageSideNav";

function SideNavBrands({ brandName }) {
  return (
    <div className="side-nav-brands col-lg-3" style={{ paddingRight: "0px" }}>
      <div
        className="d-flex align-items-center d-lg-none position-fixed opacity-75 m-2"
        style={{ zIndex: "3" }}
      >
        <button
          className="border-0 rounded-3  "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSideNavbar"
          aria-controls="offcanvasSideNavbar"
        >
          <span className="btn btn-mode p-2">
            <i className="fa-solid fa-sliders-h"></i>
          </span>
          {/* <span className="h6 mb-0 fw-semibold d-lg-none ms-2">
            User Profile
          </span> */}
        </button>
      </div>
      {/* Advanced filter responsive toggler END */}

      {/* Navbar START */}
      <nav className="navbar navbar-expand-lg mx-0">
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasSideNavbar"
        >
          {/* Offcanvas header */}
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset ms-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <BrandsHomeSideNav brandName={brandName} />

          {/* Offcanvas body */}
          {/* <div className="offcanvas-body d-block px-2 px-lg-0">
            <BrandsMenu />
          </div> */}
        </div>
      </nav>
      {/* Navbar END */}
    </div>
  );
}

export default SideNavBrands;
