import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDoc,
  doc,
  where,
  onSnapshot,
  serverTimestamp,
  getDocs,
} from "firebase/firestore";
import Picker from "emoji-picker-react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useNotification from "../hooks/useNotification";

const ListComments = ({
  shoppingListData,
  selectedShopList,
  userId,
  listId,
  role,
  notify,
  invitedBy,
}) => {
  console.log("selectedShopList from listComments", selectedShopList);
  // console.log("role from listComments", role);
  const endRef = useRef();
  const emojiPickerRef = useRef();
  const commentRef = useRef();
  const list = shoppingListData.find((list) => list.name === selectedShopList);
  const dateCreated = new Date(list?.dateCreated * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = dateCreated.toLocaleDateString("en-US", options);
  // const [recentCommentTime, setRecentCommentTime] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { user } = UserAuth();
  const [showComments, setShowComments] = useState(false);
  const { addNotification } = useNotification();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const text = commentText;
    // Clear the comment input field
    setCommentText("");
    if (role === "viewer") {
      toast.warn("You do not have permission to post a comment.", toastOptions);
      return;
    }
    console.log("CLICKED");
    try {
      // Create a new comment document with specified attributes
      const commentDocRef = await addDoc(
        collection(db, "shoppingListComments"),
        {
          comment: text,
          timestamp: serverTimestamp(),
          userId: userId,
          listId: listId,
        }
      );

      // Fetch the comments again to include the newly added comment
      fetchComments();
      const commentDocId = commentDocRef.id;
      if (notify) {
        await addNotification({
          userId: invitedBy,
          id: user?.uid,
          timestamp: serverTimestamp(),
          type: "listChat",
          by: "user",
          fields: {
            listId: listId,
            shopList: selectedShopList,
            commentId: commentDocId,
          },
        });
      }
      endRef.current.scrollIntoView({ behavior: "smooth" });
      const chatContainer = document.getElementById("chat-container"); // Replace with your chat container's ID
      chatContainer.scrollIntoView({ behavior: "smooth" });
      chatContainer.scrollTop = chatContainer.scrollHeight;
    } catch (error) {
      console.error("Error adding comment: ", error);
      // Handle any errors here, e.g., display an error message to the user
    }
  };
  const fetchComments = async () => {
    if (!listId) {
      setComments([]);
      return;
    }

    const q = query(
      collection(db, "shoppingListComments"),
      where("listId", "==", listId),
      orderBy("timestamp", "asc")
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const newComments = querySnapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          userName: "", // Initialize userName to an empty string
          photo: "",
        };
      });

      // Fetch user data for each comment asynchronously
      await Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            comment.userName = userSnapshot.data()?.userName; // Update userName
            comment.photo = userSnapshot.data()?.photo;
          } else {
            // Handle the case where the user document doesn't exist
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }
        })
      );

      // Set the comments after fetching user data
      setComments(newComments);
    });

    return unsubscribe;
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkConditions = async () => {
      if (listId) {
        // First condition: Check the 'members' subcollection
        const membersRef = collection(
          db,
          "users",
          userId,
          "shoppingList",
          selectedShopList,
          "members"
        );
        const membersSnapshot = await getDocs(membersRef);
        console.log("membersSnapshot.empty ", membersSnapshot.empty);
        if (!membersSnapshot.empty) {
          setShowComments(true);
          return; // No need to check further if this condition is true
        }

        // Second condition: Check for comments with the listId
        const q = query(
          collection(db, "shoppingListComments"),
          where("listId", "==", listId)
        );
        const querySnapshot = await getDocs(q);
        console.log("querySnapshot.empty", querySnapshot.empty);
        if (!querySnapshot.empty) {
          setShowComments(true);
          return;
        }
      }
    };

    checkConditions();
    setShowComments(false);
  }, [listId]);
  useEffect(() => {
    if (commentRef && commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
    }
  }, [comments]);

  useEffect(() => {
    if (showComments) fetchComments();
    // eslint-disable-next-line
  }, [listId, showComments]);
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emojiObject) => {
    // Implement logic to add emoji to comment text
    const emoji = emojiObject.emoji;
    setCommentText(commentText + emoji);
  };
  // const compareTime = (timestamp) => {
  //   if (!recentCommentTime || recentCommentTime !== timestamp) {
  //     setRecentCommentTime(timestamp);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // if (!showComments) {
  //   return <div></div>;
  // }
  return (
    <>
      <div
        id="listCommentsAnchor"
        className="mt-4 my-2 mx-3 bg-transaprent border border-1 rounded-2 "
      >
        <h5 className="m-0 px-3 pt-3">{selectedShopList} Chat</h5>
        <small className="px-3 fw-bold text-body">
          Created {formattedDate}
        </small>
        <hr />
        {/* <div className="d-flex justify-content-center">
          <p className=" bg-light rounded-2 my-1 px-3 py-2">
            {"Thursday 24 May 2024"}
          </p>
        </div> */}
        <div className="p-3">
          <div className="clearfix"></div>
        </div>
        {/* Comments */}
        <ul className="comment-wrap list-unstyled">
          <div class="flex-grow-1">
            <div
              class="w-100"
              ref={commentRef}
              style={{
                maxHeight: "15rem",
                overflowY: "scroll",
              }}
            >
              {comments?.map((comment, index) => (
                <li key={index} className="comment-item">
                  <div
                    className={`w-100 d-flex justify-content-${
                      comment?.userId === user?.uid ? "end" : "start"
                    }`}
                  >
                    <div
                      class={`px-2 py-1 d-flex flex-column align-items-${
                        comment?.userId === user?.uid ? "end" : "start"
                      }`}
                      style={{ maxWidth: "50%" }}
                    >
                      <div
                        className={`${
                          comment?.userId === user?.uid
                            ? "rounded-start-4 bg-primary text-white"
                            : "bg-light text-secondary rounded-end-4"
                        } p-2 ps-3 mb-1 rounded-top-4`}
                        style={{ minWidth: `15rem` }}
                      >
                        {comment.comment}
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center mx-1 ${
                          comment?.userId === user?.uid
                            ? "flex-row-reverse"
                            : "end"
                        }`}
                        style={{
                          width: "-webkit-fill-available",
                          maxWidth: "100%",
                        }}
                      >
                        <div
                          className={`h6 mb-2 d-flex align-items-center mx-1 ${
                            comment?.userId === user?.uid ? "d-none" : "end"
                          }`}
                        >
                          <Link
                            to={`/userProfile/${comment?.userId}`}
                            className="avatar avatar-xs "
                          >
                            <ProfilePicture
                              userData={comment}
                              avatarSize={15}
                              className="avatar-img rounded-circle"
                            />
                          </Link>
                          &nbsp;
                          {/* Non-breaking space */}
                          <Link
                            className="mx-1"
                            to={`/userProfile/${comment?.userId}`}
                          >
                            {comment?.userName
                              .split(" ")
                              .slice(0, 2)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}{" "}
                          </Link>
                        </div>
                        <small className="mb-2">
                          {formatTimestampForPost(comment?.timestamp)}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </div>
          </div>
        </ul>
        <hr className="m-0" />
        {/* Comment box  */}
        <form className="position-relative m-3" onSubmit={handleSubmit}>
          <div className=" rounded input-group d-flex border justify-content-center align-items-center">
            <div className="input-group-prepend">
              <span
                className="input-group-text border-0 bg-transparent"
                onClick={toggleEmojiPicker}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-emoji-smile"></i>
              </span>
            </div>

            <input
              className="form-control border-0"
              type="text"
              placeholder="Type a comment"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
            />
            <button
              className="input-group-text bg-transparent border-0"
              type="submit"
              disabled={commentText.length === 0}
            >
              <i className="bi bi-send-fill"> </i>
            </button>

            <div ref={emojiPickerRef} className="position-absolute bottom-0">
              <Picker
                onEmojiClick={addEmoji}
                open={showEmojiPicker}
                emojiStyle="native"
              />
            </div>
          </div>
        </form>
      </div>
      <div ref={endRef}></div>
    </>
  );
};

export default ListComments;
