// Import necessary components and styles
import React from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";

export default function UserDisplay({ userData, post, componentName }) {
  // Check if userData is defined before accessing properties
  if (!userData) {
    return null; // or return a loading state/component
  }

  console.log("Post in userDisplay", post);
  return (
    <div className="d-flex align-items-center w-100">
      {/* Avatar */}
      <div
        className="avatar card-title m-0 me-1"
        style={{ height: "50px", width: "50px" }}
      >
        <Link to={`/userProfile/${userData.userId}`}>
          <ProfilePicture
            userData={userData}
            className="avatar-img rounded-2"
          />
        </Link>
      </div>
      {/* Info */}
      <div
        className={`nav nav-divider d-flex ${
          componentName === "textPost" ? "flex-column" : "align-items-center"
        } justify-content-between w-100  card-header py-1 m-0 rounded-2 px-2`}
      >
        {componentName === "textPost" ? (
          <div>
            <h6 className="nav-item card-title mb-0">
              <Link to={`/userProfile/${userData.userId}`}>
                {userData.userName
                  ? userData.userName
                      .split(" ")
                      .slice(0, 2)
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")
                  : ""}
              </Link>
            </h6>
          </div>
        ) : (
          <div>
            <h5 className="m-0">{post.title}</h5>
            <h6 className="nav-item card-title fw-light mb-0">
              <Link
                className="text-body"
                to={`/userProfile/${userData.userId}`}
              >
                <span>by </span>
                <i>
                  {userData.userName
                    ? userData.userName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : ""}
                </i>
              </Link>
            </h6>
          </div>
        )}
        {/* <span className="small"> 12-12-2024</span> */}
        <span className=" small">
          {" "}
          {formatTimestampForPost(post?.dateCreated)}
        </span>
      </div>
      {/* <p className="mb-0 small">
      {userData.userTitle ? userData.userTitle : `Member since ${formatTimestamp(userData.timestamp)}`}
    </p> */}
    </div>
  );
}
