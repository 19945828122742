import React, { useEffect } from 'react';
import { Adsense } from '@ctrl/react-adsense';


const GoogleAdCard2 = () => {
//   useEffect(() => {
//     // Ensure the ad script runs after the component renders
//     try {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     } catch (e) {
//       console.error("AdSense error: ", e);
//     }
//   }, []);
  return (
    <Adsense
    style={{ width: "250px", height: "250px", display: 'block',marginBottom:"4px"}}
        client="ca-pub-6917679702799024"
        slot="5663879540"
          format="fluid"
          layout="+s+rx+1+2-3"
        // adTest='on' //Dev Only
    />
)

};

export default GoogleAdCard2;
