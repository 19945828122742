import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import ProfilePicture from "../UserProfile/ProfilePicture";
import FeedReaction from "./FeedReaction";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";

function PostDetails() {
  const { id } = useParams();
  const [postData, setPostData] = useState(null); // State to store post data
  const [loading, setLoading] = useState(true); // State for loading indication
  const [imageLoading, setImageLoading] = useState(true); // State for image loading indication
  const { userData } = useFetchUserData(postData?.userId);

  useEffect(() => {
    // Fetch post data from Firestore
    const fetchPost = async () => {
      try {
        const postRef = doc(db, "posts", id); // Reference to the post document
        const postSnap = await getDoc(postRef); // Get the document snapshot
        if (postSnap.exists()) {
          setPostData({ ...postSnap.data(), id: postSnap.id }); // Set the post data
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };
    if (id) fetchPost();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!postData) {
    return <div>Post not found!</div>;
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="card card-body">
              {/* Placeholder for image while loading */}

              {imageLoading && (
                <p className="card-text placeholder-glow">
                  <span className="placeholder col-12 dynamic-placeholder"></span>
                </p>
              )}

              {/* Image will be shown after loading */}
              {postData.thumbnailUrl && (
                <img
                  className={`card-img rounded ${imageLoading ? "d-none" : ""}`}
                  src={postData.thumbnailUrl}
                  alt="Post Thumbnail"
                  onLoad={() => setImageLoading(false)} // Set imageLoading to false after image is loaded
                />
              )}

              {/* Feed meta START */}
              <div className="d-flex align-items-center justify-content-between my-3">
                <div className="d-flex align-items-center">
                  {/* Avatar */}
                  <div
                    className="avatar me-2"
                    style={{ width: "35px", height: "35px" }}
                  >
                    <Link to={`/userProfile/${userData?.id}`}>
                      <ProfilePicture
                        userData={userData}
                        className="avatar-img rounded"
                      />
                     </Link>
                  </div>
                  {/* Info */}
                  <div>
                    <div className="nav nav-divider">
                      <h6 className="nav-item card-title mb-0">
                        <Link to={`/userProfile/${userData?.id}`}>
                          {userData?.userName ? userData?.userName : ""}
                        </Link>
                      </h6>
                      <span className="m-1 small">{formatTimestampForPost(postData?.dateCreated)}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Post content */}
              <p>{postData.content}</p>

              <FeedReaction post={postData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostDetails;
