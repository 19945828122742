import React from "react";
import { UserAuth } from "../Context";
import { useParams } from "react-router";

export default function UserAbout({ userData }) {
  const { user } = UserAuth();
  const { uid } = useParams();

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) {
      return "N/A"; // Or any default value you prefer when timestamp is null
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    return timestamp.toDate().toLocaleDateString("en-US", options);
  };
  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];

    console.log('userData in about :',userData);
    // Function to add ordinal suffix to day
    const getOrdinalSuffix = (num) => {
      if (num >= 10 && num <= 20) return "th";
      switch (num % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Format the day with the correct suffix
    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

    return `${dayWithSuffix} ${month}`;
  }
  return (
    <>
      {/* Card feed item START */}
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <div class="card">
          {/* Card header START */}
          <div class="card-header border-0 pb-0">
            <h5 class="card-title"> Profile Info</h5>
          </div>
          {/* Card header END */}
          {/* Card body START */}
          <div class="card-body">
            <div class="rounded border px-3 py-2 mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <h6>Overview</h6>
                {/* <div class="dropdown ms-auto">
                
                <Link class="nav nav-link text-secondary mb-0" href="#" id="aboutAction" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </Link>
                
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction">
                  <li><Link class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</Link></li>
                  <li><Link class="dropdown-item" href="#"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</Link></li>
                </ul>
              </div> */}
              </div>
              <p>{userData ? userData.overview : ""}</p>
            </div>
            <div class="row g-4">
              <div class="col-sm-6">
                {/* Birthday START */}
                <div class="d-flex align-items-center rounded border px-3 py-2">
                  {/* Date */}
                  <p class="mb-0">
                    <i class="bi bi-calendar-date fa-fw me-2"></i> Birthday:{" "}
                    <strong>{formatDate(userData?.birthday)}</strong>
                  </p>
                  {/* <div class="dropdown ms-auto">
                  <Link class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </Link>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</Link></li>
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</Link></li>
                  </ul>
                </div> */}
                </div>
                {/* Birthday END */}
              </div>
              {/* <div class="col-sm-6"> */}
              {/* Status START */}
              {/* <div class="d-flex align-items-center rounded border px-3 py-2"> */}
              {/* Date */}
              {/* <p class="mb-0">
                  <i class="bi bi-heart fa-fw me-2"></i> Status: <strong> Single </strong>
                </p> */}
              {/* <div class="dropdown ms-auto">
                  <Link class="nav nav-link text-secondary mb-0" href="#" id="aboutAction3" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </Link>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction3">
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</Link></li>
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</Link></li>
                  </ul>
                </div> */}
              {/* </div> */}
              {/* Status END */}
              {/* </div> */}
              <div class="col-sm-6">
                {/* Lives START */}
                <div class="d-flex align-items-center rounded border px-3 py-2">
                  {/* Date */}
                  <p class="mb-0">
                    <i class="bi bi-geo-alt fa-fw me-2"></i> Lives in:{" "}
                    <strong> {userData ? userData.location : ""} </strong>
                  </p>
                  {/* <div class="dropdown ms-auto">
                  <Link class="nav nav-link text-secondary mb-0" href="#" id="aboutAction5" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </Link>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction5">
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</Link></li>
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</Link></li>
                  </ul>
                </div> */}
                </div>
                {/* Lives END */}
              </div>
              <div class="col-sm-6">
                {/* Joined on START */}
                <div class="d-flex align-items-center rounded border px-3 py-2">
                  {/* Date */}
                  <p className="mb-0">
                    <i className="bi bi-geo-alt fa-fw me-2"></i>{" "}
                    {userData ? (
                      <>
                        Joined on:{" "}
                        <strong>{formatTimestamp(userData.timestamp)}</strong>
                      </>
                    ) : (
                      "Joined on N/A"
                    )}
                  </p>
                  {/* <div class="dropdown ms-auto">
                  <Link class="nav nav-link text-secondary mb-0" href="#" id="aboutAction6" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </Link>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction6">
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</Link></li>
                    <li><Link class="dropdown-item" href="#"> <i class="bi bi-trash fa-fw pe-2"></i>Delete</Link></li>
                  </ul>
                </div> */}
                </div>
                {/* Joined on END */}
              </div>
              <div class="col-sm-6">
                {/* Joined on START */}
                {/* <div class="d-flex align-items-center rounded border px-3 py-2">
                  <p class="mb-0">
                    <i class="bi bi-envelope fa-fw me-2"></i> Email:{" "}
                    <strong>{userData ? userData.email : ""}</strong>
                  </p>
                 
                </div> */}
                {/* Joined on END */}
              </div>
              {/* <div class="col-sm-6 position-relative">
             
              <Link class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a workplace</Link>
             
            </div>
            <div class="col-sm-6 position-relative">
              
              <Link class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a education</Link>
              
            </div> */}
            </div>
          </div>
          {/* Card body END */}
        </div>
      )}
      {!isCurrentUserProfile && privacy === "private" && !isUserInFriendList && (
        <>
          <div class="card">
            <div class="card-header border-0 pb-0">
              <h5 class="card-title">Profile Info</h5>
            </div>
            <div class="card-body">
              <p>This profile is private.</p>
            </div>
          </div>
        </>
      )}

      {/* Card feed item END */}
    </>
  );
}
